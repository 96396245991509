import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import { useNavigate, useSearchParams } from "react-router-dom";
import CourseAssessmentResultsApiService from "../../../../services/api/CourseAssessmentResultsApiService";
import "./CourseAssessmentTable.css";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import PendingActionsIcon from "@mui/icons-material/PendingActions";

const CourseAssessmentTable = () => {
    const [assessments, setAssessments] = useState([]);
    const [searchText, setSearchText] = useState("");
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const status = searchParams.get("status");

    // Fetch assessments when the component loads
    useEffect(() => {
        const fetchAssessments = async () => {
            try {
                const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
                const token = userInfo.token;

                let response = await CourseAssessmentResultsApiService.getAllCourseAssessmentResults(token);

                // Filter assessments based on status param
                if (status === "completed") {
                    response = response.filter((assessment) => assessment.status === "COM");
                }

                // Sort assessments by latest submission date
                const sortedAssessments = response.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date));

                setAssessments(sortedAssessments);
            } catch (error) {
                console.error("Error fetching course assessment results:", error);
            }
        };

        fetchAssessments();
    }, []);

    // Handle row click
    const handleRowClick = (params) => {
        const clickedCAId = params.row.id;
        const clickedExamName = params.row.exam_name;

        if (status === "completed") {
            navigate(`/analysis/${clickedCAId}`, { state: { examName: clickedExamName } });
        } else {
            navigate(`/marking/get-submitted-tests/${params.row.exam_paper}/${params.row.course}/${clickedCAId}`);
        }
    };


    // Utility function to get initials for Avatar
    const getInitials = (name) => {
        if (!name) return '';
        return name.slice(0, 2).toUpperCase();  // Get the first two letters of the name
    };

    // DataGrid column definitions
    const columns = [
        {
            field: "exam_name",
            headerName: "Assessment Name",
            flex: 2,
            align: "left",
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Avatar sx={{ width: 32, height: 32, marginRight: 1, backgroundColor: '#5f9fa1' }}>
                        {getInitials(params.value)}
                    </Avatar>
                    <span>{params.value}</span>
                </div>
            ),
        },
        {
            field: "course_name",
            headerName: "Course Name",
            flex: 1.5,
            align: "left",
            headerClassName: "centered-header",
        },
        {
            field: "total_submitted",
            headerName: "Submitted",
            flex: 1,
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                    {params.value}
                </div>
            ),
        },
        {
            field: "total_learners",
            headerName: "Marked",
            flex: 1,
            headerClassName: "centered-header",
            renderCell: (params) => (
                <div style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>
                    {params.row.total_submitted}
                </div>
            ),
        },
        {
            field: "status_display",
            headerName: "Status",
            flex: 1,
            align: "center",
            headerClassName: "centered-header",
        },
    ];

    // Filter assessments based on search input
    const filteredAssessments = assessments.filter(
        (assessment) =>
            assessment.course_name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.exam_name.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.assessment_date?.toLowerCase().includes(searchText.toLowerCase()) ||
            assessment.status.toLowerCase().includes(searchText.toLowerCase()),
    );

    return (
        <>
            <div style={{
                display: "flex",
                justifyContent: "flex-start",
                margin: "20px",
                flexDirection: "column",
                gap: "30px",
            }}>
                {/* Marking Title and Search Bar */}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
                    {/* Marking Title (Icon + Text) on the Left */}
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", color: "#48535c" }}>
                        {status === "completed" ? (
                            <AssignmentTurnedInIcon />
                        ) : (
                            <PendingActionsIcon />
                        )}
                        <span style={{ fontWeight: "bold" }}>
                        {status === "completed" ? "Marked Tests" : "Marking Requests"}
                    </span>
                    </div>

                    {/* Search Bar on the Right */}
                <TextField
                    id="search"
                    placeholder="Search"
                    size="small"
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    InputProps={{
                        style: { width: "200px", borderRadius: 25 },
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: "25px",
                            borderColor: "#d0d4d9",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#d0d4d9",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#b3b3b3",
                        },
                        "& .MuiInputBase-input": {
                            padding: "10px",
                            color: "#5f9ea0",
                        },
                    }}
                />
                </div>

                {/* Data Grid for Assessments */}
                <div style={{ height: 400, width: "100%" }}>
                    <DataGrid
                        rows={filteredAssessments}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                        disableSelectionOnClick
                        autoHeight
                        onRowClick={handleRowClick}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                            boxShadow: 0,
                            border: "1px solid #d0d4d9",  // Light border around the grid
                            [`& .${"MuiDataGrid-cell"}:focus-within`]: {
                                outline: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "1px solid #d0d4d9",  // Add a light border between rows
                                padding: "20px",  // Keep padding for cell content
                                whiteSpace: "nowrap",  // Prevent text wrapping
                                overflow: "hidden",  // Hide overflowed text
                                textOverflow: "ellipsis",  // Show ellipsis for long text
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#f3f7f8",  // Set the desired background color for column headers
                                borderBottom: "1px solid #d0d4d9",  // Add bottom border for column headers
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                color: "#555f67",  // Set text color to match the style you requested
                                fontWeight: "bold",  // Make the header text bold
                            },
                            "& .MuiDataGrid-columnSeparator--sideRight, & .MuiDataGrid-iconButtonContainer": {
                                display: "none", // Hides the column menu button and vertical lines
                            },
                            "& .MuiDataGrid-sortIcon": {
                                display: "none", // Hides the sort icon
                            },
                            // Add hover effect for rows
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#dce7ea",  // Set a darker background color on hover
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );

};

export default CourseAssessmentTable;
