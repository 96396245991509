import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CourseApiService from "../../services/api/courseAPIService";
import SubjectApiService from "../../services/api/subjectAPIService";


const ManageCourses = () => {
    const [courses, setCourses] = useState([]);
    const [newCourse, setNewCourse] = useState({ courseName: '', grade: '', group: '', subject: '' });
    const [filteredSubjects, setFilteredSubjects] = useState([]);  // Holds fetched subjects
    const [availableGrades, setAvailableGrades] = useState([]);  // Holds the grades available for the selected subject
    const [open, setOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);  // Track if editing
    const [editCourseId, setEditCourseId] = useState(null);  // Track course to edit
    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user");
const parsedUserInfo = JSON.parse(userInfo || "{}");
const { token } = parsedUserInfo;

const classOptions = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

    // Fetch courses and subjects on component mount
    useEffect(() => {
        fetchCourses();
        fetchSubjects();  // Fetch all subjects from the backend
    }, []);

    // Fetch all courses for the teacher
    const fetchCourses = async () => {
        try {
            const response = await CourseApiService.getCoursesForTeacher(token);
            if (response.ok) {
                const coursesData = await response.json();
                setCourses(coursesData);
            } else {
                console.error("Failed to fetch courses.");
            }
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    // Fetch all subjects with associated grades from the backend
    const fetchSubjects = async () => {
        try {
            const response = await SubjectApiService.fetchSubjects(token);
            if (response.ok) {
                const subjectsData = await response.json();
                setFilteredSubjects(subjectsData);  // Set fetched subjects
            } else {
                console.error("Failed to fetch subjects.");
            }
        } catch (error) {
            console.error("Error fetching subjects:", error);
        }
    };

    // Automatically generate the course name based on selected subject, grade, and class
    const generateCourseName = (subject, grade, group) => {
        if (subject && grade && group) {
            return `${subject} - Grade ${grade} - ${group}`;
        }
        return '';
    };

    // Handle subject change, populate available grades and update course name
    const handleSubjectChange = (event) => {
        const selectedSubject = event.target.value;
        const selectedSubjectObj = filteredSubjects.find(subject => subject.name === selectedSubject);

        // Set all grades (4–12) regardless of what's currently available in courses
        const gradesForSubject = selectedSubjectObj?.grades.map(g => g.grade) || Array.from({ length: 9 }, (_, i) => i + 4);

        setAvailableGrades(gradesForSubject); // Extract grades for the selected subject
        setNewCourse((prev) => ({
            ...prev,
            subject: selectedSubject,
            grade: '',  // Reset the grade selection
            courseName: generateCourseName(selectedSubject, '', prev.group)
        }));
    };

    // Handle grade change and update course name
    const handleGradeChange = (event) => {
        const selectedGrade = event.target.value;
        setNewCourse((prev) => ({
            ...prev,
            grade: selectedGrade,
            courseName: generateCourseName(prev.subject, selectedGrade, prev.group)
        }));
    };

    // Handle class (group) change and update course name
    const handleClassChange = (event) => {
        const selectedGroup = event.target.value;
        setNewCourse((prev) => ({
            ...prev,
            group: selectedGroup,
            courseName: generateCourseName(prev.subject, prev.grade, selectedGroup)
        }));
    };

    // Add or edit a course
    const handleCourseSubmit = async (e) => {
        e.preventDefault();
        const courseData = {
            course_name: newCourse.courseName,
            grade: newCourse.grade,
            subject_name: newCourse.subject,
            group: newCourse.group,
            start_date: new Date().toISOString().split('T')[0],
            end_date: new Date().toISOString().split('T')[0]
        };

        try {
            let response;
            if (isEditing && editCourseId) {
                // Editing an existing course
                response = await CourseApiService.updateCourse(editCourseId, courseData, token);
            } else {
                // Creating a new course
                response = await CourseApiService.createCourse(courseData, token);
            }

            if (response.ok) {
                fetchCourses();
                handleCloseModal();
            } else {
                console.error("Failed to save course.");
            }
        } catch (error) {
            console.error("Error saving course:", error);
        }
    };

    // Open the modal to edit a course
    const handleOpenEditModal = (course) => {
        setIsEditing(true);
        setEditCourseId(course.id);
        setNewCourse({
            courseName: course.name,
            grade: course.grade,
            subject: course.subject,
            group: course.group
        });
        const selectedSubjectObj = filteredSubjects.find(subject => subject.name === course.subject);
        setAvailableGrades(selectedSubjectObj.grades.map(g => g.grade)); // Populate grades for the selected subject
        setOpen(true);
    };

    // Delete course
    const handleCourseDelete = async (courseId) => {
        const isConfirmed = window.confirm("Are you sure you want to delete this course?");
        if (isConfirmed) {
            try {
                await CourseApiService.deleteCourse(courseId, token);
                fetchCourses(); // Refresh the course list after deletion
            } catch (error) {
                console.error("Error deleting course:", error);
            }
        }
    };

    // Open the modal to add a course
    const handleOpenModal = () => {
        setIsEditing(false);  // Reset editing state
        setEditCourseId(null);  // Clear any existing course ID
        setNewCourse({ courseName: '', grade: '', subject: '', group: '' });
        setAvailableGrades([]);  // Reset available grades
        setOpen(true);
    };

    // Close the modal
    const handleCloseModal = () => {
        setOpen(false);
    };

    return (
        <div>
            <Card
                sx={{
                    borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: { xs: '5px', sm: '30px', md: '40px' },
                    width: { xs: '100vw', sm: '100vw', md: '80vw' },
                    maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                    margin: { xs: '0px', sm: '30px', md: '40px' },
                }}
            >
                <CardContent>
                   <Box display="flex" justifyContent="space-between" alignItems="center" mt={3}>
                    <Typography variant="h5">Manage Courses</Typography>
                    <Button variant="contained" color="primary" onClick={handleOpenModal}>
                        {"Add Course"}
                    </Button>
                   </Box>
                    <Table
                    sx={{
                        maxHeight: '300px',
                        overflowY: 'auto',
                    }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Course Name</TableCell>
                                <TableCell>Grade</TableCell>
                                <TableCell>Subject</TableCell>
                                <TableCell>Class</TableCell>
                                 <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {courses.map((course, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        <Button onClick={() => navigate(`/manage-learners/${course.id}/${course.name}`)}>
                                            {course.name}
                                        </Button>
                                    </TableCell>
                                    <TableCell>{course.grade ? `Grade ${course.grade}` : 'No Grade'}</TableCell>
                                    <TableCell>{course.subject}</TableCell>
                                    <TableCell>{course.group}</TableCell> {/* Display the group for each course */}

                                    <TableCell>
                                        <IconButton onClick={() => handleOpenEditModal(course)} color="primary">
                                            <Edit />
                                        </IconButton>
                                        <IconButton onClick={() => handleCourseDelete(course.id)} color="tertiary">
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

                    {/* Add or Edit Course Modal */}
                    <Dialog open={open} onClose={handleCloseModal}>
                        <DialogTitle>{isEditing ? "Edit Course" : "Add Course"}</DialogTitle>
                        <DialogContent>
                            <form onSubmit={handleCourseSubmit}>
                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                                    <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                                        Subject<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                                        <Select
                                            name="subject"
                                            value={newCourse.subject}
                                            onChange={handleSubjectChange}
                                            displayEmpty
                                            sx={{ height: '35px' }}
                                        >
                                            <MenuItem value="" disabled>Select a subject</MenuItem>
                                            {filteredSubjects.map((subject, index) => (
                                                <MenuItem key={index} value={subject.name}>{subject.name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                                    <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                                        Grade<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                                        <Select
                                            name="grade"
                                            value={newCourse.grade}
                                            onChange={handleGradeChange}
                                            displayEmpty
                                            sx={{ height: '35px' }}
                                        >
                                            <MenuItem value="" disabled>Select a grade</MenuItem>
                                            {availableGrades.map((grade, index) => (
                                                <MenuItem key={index} value={grade}>{`Grade ${grade}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, mb: 1 }}>
                                    <Typography sx={{ color: '#777f86', mb: 0.5, fontSize: '0.9rem', fontWeight: 'bold', textAlign: 'left' }} variant="body1">
                                        Class<span style={{ color: 'red' }}>*</span>
                                    </Typography>
                                    <FormControl fullWidth variant="outlined" margin="dense" sx={{ height: '35px' }}>
                                        <Select
                                            name="group"
                                            value={newCourse.group}
                                            onChange={handleClassChange}
                                            displayEmpty
                                            sx={{ height: '35px' }}
                                        >
                                            <MenuItem value="" disabled>Select a group</MenuItem>
                                            {classOptions.map((classOption, index) => (
                                                <MenuItem key={index} value={classOption}>{`Class ${classOption}`}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>

                                <DialogActions>
                                    <Button onClick={handleCloseModal} color="secondary">
                                        Cancel
                                    </Button>
                                    <Button type="submit" color="primary" disabled={!newCourse.grade || !newCourse.subject || !newCourse.group}>
                                        {isEditing ? "Update Course" : "Add Course"}
                                    </Button>
                                </DialogActions>
                            </form>
                        </DialogContent>
                    </Dialog>
                </CardContent>
            </Card>
        </div>
    );
};

export default ManageCourses;
