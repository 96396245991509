import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Grid, Typography } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

const CourseAssessmentsWidgets = ({
  courseAssessments,
  handleNewMarkingSubmission,
  handleWidgetClick,
  handleDeleteAssessment, // Function to handle deletion
  handleViewResults, // Function to handle viewing results
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedAssessment, setSelectedAssessment] = useState(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleMenuOpen = (event, assessment) => {
    setAnchorEl(event.currentTarget);
    setSelectedAssessment(assessment);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedAssessment(null);
  };

  const handleDeleteClick = () => {
    if (selectedAssessment) {
      handleDeleteAssessment(selectedAssessment.id); // Call the delete function
    }
    handleMenuClose();
  };

  // Sort assessments by 'assessment_date' field in descending order
  const sortedAssessments = courseAssessments.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date));

  return (
    <>
      {/* New submission button */}
      <div style={{ margin: "10px", display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
        <Button
          variant="contained"
          color="primary"
          sx={{ borderRadius: "25px", backgroundColor: "#5f9ea0", padding: '6px 16px' }} // Reduced padding
          onClick={handleNewMarkingSubmission}
        >
          New Marking
        </Button>
      </div>

      {/* Cards displaying the assessments */}
      <Grid container spacing={6}>
        {sortedAssessments.map((assessment) => (
          <Grid item xs={12} sm={6} md={23} key={assessment.id}>
            <Card
              sx={{
                background: "#ffffff", // White background
                borderRadius: "8px", // Slightly smaller border-radius
                border: "1px solid #b6d7d7", // Updated border color
                position: "relative", // Ensure the menu is positioned at the top-right
                transition: "transform 0.3s ease",
                "&:hover": {
                  transform: "translateY(-3px)", // Slightly smaller hover effect
                  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                },
              }}
              onDoubleClick={() => handleWidgetClick(assessment)} // Double-click event handler
            >
              {/* Menu at the top-right */}
              {/*<IconButton*/}
              {/*  aria-label="more options"*/}
              {/*  onClick={(e) => handleMenuOpen(e, assessment)}*/}
              {/*  sx={{ position: "absolute", top: "5px", right: "5px", color: "#666" }}*/}
              {/*>*/}
              {/*  <MoreVert />*/}
              {/*</IconButton>*/}

              <CardContent sx={{ padding: "10px" }}> {/* Reduced padding */}
                <Box display="flex" alignItems="center">
                  {/* Course Name in Bold and Smaller */}
                  <Typography variant="h6" sx={{ fontWeight: "bold", color: "#333", fontSize: "16px", marginBottom: "4px" }}>
                    {assessment.course_name}
                  </Typography>

                  {/* Conditionally show the green tick if status is "Marking Completed" */}
                  {assessment.status === "COM" && (
                    <CheckCircleOutline
                      sx={{ color: "green", marginLeft: "8px", fontSize: "20px" }} // Green check for completed marking
                    />
                  )}
                </Box>

                {/* Assessment Name under Course */}
                <Typography variant="body2" sx={{ color: "#666", marginBottom: "4px" }}>
                  Assessment: {assessment.exam_name}
                </Typography>

                {/* Status Display */}
                <Typography variant="body2" sx={{ color: "#666", marginBottom: "4px" }}>
                  {assessment.status_display}
                </Typography>

                {/* Pass Mark Display */}
                <Typography variant="body2" sx={{ color: "#666", marginBottom: "4px" }}>
                  Pass Mark: {assessment.pass_mark}%
                </Typography>

                {/* Show "View Results" button if marking is completed */}
                {assessment.status === "COM" && (
                  <Button
                    size="small"
                    sx={{
                      marginTop: "10px",
                      padding: "0",
                      minWidth: "unset", // Removes default button size
                      background: "none", // No background
                      color: "#5f9ea0", // Custom color for the text
                      "&:hover": {
                        background: "none", // No background on hover
                      },
                    }}
                    onClick={() => handleViewResults(assessment.id)}
                  >
                    View Results
                  </Button>
                )}

                {/* Show "Submit Answers" button if ready for learner submissions */}
                {assessment.status === "CRE" && (
                  <Button
                    size="small"
                    sx={{
                      marginTop: "10px",
                      padding: "0",
                      minWidth: "unset", // Removes default button size
                      background: "none", // No background
                      color: "#5f9ea0", // Custom color for the text
                      "&:hover": {
                        background: "none", // No background on hover
                      },
                    }}
                    onClick={() => handleWidgetClick(assessment)}
                  >
                    Submit Answers
                  </Button>
                )}
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {/*/!* Menu component *!/*/}
      {/*<Menu*/}
      {/*  anchorEl={anchorEl}*/}
      {/*  open={Boolean(anchorEl)}*/}
      {/*  onClose={handleMenuClose}*/}
      {/*>*/}
      {/*  <MenuItem onClick={handleDeleteClick}>Delete</MenuItem>*/}
      {/*</Menu>*/}
    </>
  );
};

export default CourseAssessmentsWidgets;
