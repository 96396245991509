import React, { useRef, useState } from "react";
import {
    Box,
    Button,
    Grid,
    IconButton,
    InputAdornment,
    Link,
    TextField,
    Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AuthenticationAPIService } from "../../../services/api/AuthenticationAPIService";
import Footer from "../footer/Footer";
import "./Login.css";

const Login = () => {
    const navigate = useNavigate();
    const [credentials, setCredentials] = useState({ email: "", password: "" });
    const [errors, setErrors] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [loginError, setLoginError] = useState("");
    const continueButtonRef = useRef(null);

    const handleClickShowPassword = () => {
        setShowPassword((prev) => !prev);
    };

    const handleSignIn = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        setLoginError(""); // Clear previous login errors
        const newErrors = {};

        if (!credentials.email) {
            newErrors.email = "Please enter an email address.";
        }

        if (!credentials.password) {
            newErrors.password = "Please enter the password.";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            try {
                const response = await AuthenticationAPIService.login(credentials);
                const res = await response.json();

                if (!response.ok) {
                    if (res.error === "Incorrect password") {
                        setLoginError("The password you entered is incorrect. Please try again.");
                    } else if (res.error === "User not found") {
                        setLoginError("The email you entered does not exist. Please check and try again.");
                    } else {
                        setLoginError("Login failed, please try again later.");
                    }
                    setIsSubmitting(false);
                    return;
                }

                localStorage.setItem(
                    "user",
                    JSON.stringify({
                        token: res.token,
                        name: `${res.first_name} ${res.last_name}`,
                        employerId: res.employer_id,
                        userId: res.user_id,
                        user_role: res.user_role,
                        email: res.email,
                        profilePic: res.profile_picture,
                    })
                );
                navigate("/home");
            } catch (error) {
                setLoginError("An unexpected error occurred. Please try again later.");
            }
        }
        setIsSubmitting(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setCredentials((prev) => ({ ...prev, [name]: value }));
    };

    return (
        <Grid container className="sign-in-page-container">
            <Grid item xs={12} className="sign-in-page-card-wrapper">
                <Box className="sign-in-page-card">
                    <p className="sign-in-page-title">Sign in to Educase</p>
                    <form onSubmit={handleSignIn} className="sign-in-form">
                        {loginError && (
                            <Typography
                                variant="body2"
                                sx={{
                                    color: "#d32f2f",
                                    backgroundColor: "#ffe6e6",
                                    padding: "10px",
                                    borderRadius: "8px",
                                    border: "1px solid #f5c2c2",
                                    marginBottom: "10px",
                                    fontWeight: "bold",
                                }}
                            >
                                {loginError}
                            </Typography>
                        )}

                        <TextField
                            name="email"
                            label="Email"
                            variant="outlined"
                            fullWidth
                            placeholder="Email"
                            value={credentials.email}
                            onChange={handleInputChange}
                            error={!!errors.email}
                            helperText={errors.email}
                            margin="dense"
                            sx={{
                                "& .MuiInputBase-root": { height: "45px" },
                                "& .MuiInputLabel-root": {
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#323e48",
                                },
                                "& fieldset": {
                                    borderColor: "#5F9EA0",
                                    borderRadius: "10px",
                                },
                            }}
                        />
                        <TextField
                            name="password"
                            label="Password"
                            variant="outlined"
                            fullWidth
                            placeholder="Password"
                            type={showPassword ? "text" : "password"}
                            value={credentials.password}
                            onChange={handleInputChange}
                            error={!!errors.password}
                            helperText={errors.password}
                            margin="dense"
                            sx={{
                                "& .MuiInputBase-root": { height: "45px" },
                                "& .MuiInputLabel-root": {
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    color: "#323e48",
                                },
                                "& fieldset": {
                                    borderColor: "#5F9EA0",
                                    borderRadius: "10px",
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                        >
                                            {showPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <Box sx={{ textAlign: "right", mt: 2 }}>
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => navigate("/forgot-password")}
                                sx={{ color: "#00c1e4", textDecoration: "none" }}
                                type="button"
                            >
                                Forgot Password?
                            </Link>
                        </Box>
                        <Button
                            variant="contained"
                            fullWidth
                            type="submit"
                            ref={continueButtonRef}
                            disabled={isSubmitting}
                            sx={{
                                backgroundColor: isSubmitting ? "#cccccc" : "#00c1e4",
                                color: "#fff",
                                padding: "12px 0",
                                fontSize: "16px",
                                borderRadius: "10px",
                                marginTop: "20px",
                                "&:hover": {
                                    backgroundColor: isSubmitting ? "#cccccc" : "#00b0d4",
                                },
                            }}
                        >
                            Continue
                        </Button>
                    </form>
                    <Box
                        mt={2}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            textAlign: "center",
                        }}
                    >
                        <Typography variant="body2">
                            Not a Member yet?{" "}
                            <Link
                                component="button"
                                variant="body2"
                                onClick={() => navigate("/auth/registration")}
                                sx={{
                                    color: "#00c1e4",
                                    textDecoration: "none",
                                    fontWeight: "bold",
                                }}
                            >
                                Sign up
                            </Link>
                        </Typography>
                    </Box>
                    <Footer />
                </Box>
            </Grid>
        </Grid>
    );
};

export default Login;
