import React, { useEffect, useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import NavBar from "./components/navBar/navBar";
import ExamTestScreen from "./features/ExamTestsScreen/ExamTestsScreen";
import CreateAssessmentWizard from "./features/CreateAssessmentWizard";
import CreateAssessmentPaperStep from "./features/CreateAssessmentWizard/createAssessmentPaperStep";
import LandingPage from "./features/landingPage/landingPage";
import ResetPasswordPage from "./features/ResetPassword/resetPassword";
import ChoosePassword from "./features/ChoosePassword/ChoosePassword";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import FeedbackPage from "./features/AIMarking";
import AssessmentContainer from "./features/Assessment/AssessmentContainer/AssessmentContainer";
import ReviewAIMarking from "./features/ReviewAIMarking/ReviewAIMarking";
import ThankYouScreen from "./features/Assessment/ThankYouComponent/ThankYouScreen";
import TestSignInPage from "./features/Assessment/WelcomeTestScreenAuthContainer/TestSignInPage/TestSignInPage";
import TestSignUpPage from "./features/Assessment/WelcomeTestScreenAuthContainer/TestSignUpPage/TestSignUpPage";
import TestWelcomePage from "./features/Assessment/WelcomeContainer/TestWelcomePage";
import TestInstructionsPage from "./features/Assessment/InstructionContainer/TestInstructionsPage";
import UploadAnswers from "./features/Assessment/UploadAnswers/UploadAnswers";
import StudentResultsContainer from "./features/Assessment/StudentResultsContainer/StudentResultsContainer";
import Home from "./features/Dashboard/home";
import ProfileEdit from "./features/ProfileEdit/profileEdit";
import CreateAnnouncement from "./features/CreateAnnouncement/CreateAnnouncement";
import ManageSubjectsTopics from "./features/ManageTopicSubject/manageTopicSubject";
import Header from "./components/header/header";
import Help from "./features/Help/Help";
import Metrics from "./features/Metrics/Metrics";
import UploadScripts from "./features/UploadMarks/uploadScripts";
import TermsOfService from "./features/TermsOfService/TermsOfService";
import NotFoundPage from "./features/NotFound/notFoundPage";
import OnboardingPage from "./features/OnboardingPage/OnboardingPage";
import Login from "./features/auth/login/Login";
import Registration from "./features/auth/registration/Registration";
import Marking from "./features/UploadMarks/ManualMarking/Marking";
import TeacherOnboarding from "./features/OnboardingPage/TeacherOnbording/TeacherOnboarding";
import SchoolOnboarding from "./features/OnboardingPage/SchoolOnbording/SchoolOnboarding";
import ManageLearners from "./features/ManageLearners/ManageLearners";
import ManageCourses from "./features/ManageCourses/ManageCourses";
import LearningGapsBlog from "./features/Blogs/LearningGapsBlog";
import AssessmentTools from "./features/AITool";
import UploadLearnerScripts from "./features/UploadMarks/UploadScripts/UploadLearnerScripts";
import ScriptDetails from "./features/UploadMarks/ScriptsDetails/ScriptDetails";
import CourseAssessmentTable from "./features/UploadMarks/ManualMarking/CourseAssessmentTable/CourseAssessmentTable";
import MarkingClassList from "./features/UploadMarks/ManualMarking/ClassList/ClassList";
import AuthWrapper from "./components/Common/AuthWrapper/AuthWrapper";
import "katex/dist/katex.min.css";
import "./App.css";
import store from "./store/store";
import Analysis from "./features/Analysis/Analysis";

// Create a custom theme
const theme = createTheme({
    palette: {
        primary: {
            main: '#5F9EA0',
        },
        secondary: {
            main: '#F5FFFA',
        },
        tertiary: {
            main: '#80E0B1',
        }
    },
});

const DefaultLayout = () => {
    const [, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 760);
        };

        handleResize(); // Initial check
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <div className="App">
            <NavBar /> {/* Already styled with .nav-bar */}

            <div className="Content-container">
                <Header /> {/* Header at the top */}

                <div className="Content">
                    <Routes>
                        {routes.map((route) => (
                            <Route
                                key={route.path}
                                path={route.path}
                                element={
                                    route.requiresAuth ? (
                                        <AuthWrapper>{route.element}</AuthWrapper>
                                    ) : (
                                        route.element
                                    )
                                }
                            />
                        ))}
                    </Routes>
                </div>
            </div>
        </div>


    );
};

const authenticationRoutes = [
    {
        path: "/",
        element: <LandingPage />,
        requiresAuth: false,
    },
    {
        path: "/*",
        element: <DefaultLayout />,
        requiresAuth: false,
    },
    {
        path: "/auth/login",
        element: <Login />,
        requiresAuth: false,
    },
    {
        path: "/auth/registration",
        element: <Registration />,
        requiresAuth: false,
    },
    {
        path: "/onboarding-page",
        element: <OnboardingPage />,
        requiresAuth: false,
    },
    {
        path: "/forgot-password",
        element: <ResetPasswordPage />,
        requiresAuth: false
    },
    {
        path: "/choose-password/:uidb64/:token",
        element: <ChoosePassword />,
        requiresAuth: false
    },
    {
        path: "/test-instructions",
        element: <TestInstructionsPage />,
        requiresAuth: true
    },
    {
        path: "/test-sign-in",
        element: <TestSignInPage />,
        requiresAuth: false
    },
    {
        path: "/test-sign-up",
        element: <TestSignUpPage />,
        requiresAuth: false
    },
    {
        path: "/test-welcome-page",
        element: <TestWelcomePage />,
        requiresAuth: false
    },
    {
        path: "/assessment",
        element: <AssessmentContainer />,
        requiresAuth: false
    },
    {
        path: "/upload-answers",
        element: <UploadAnswers />,
        requiresAuth: false
    },
    {
        path: "/thank-you",
        element: <ThankYouScreen />,
        requiresAuth: false
    },
    {
        path: "/results",
        element: <StudentResultsContainer />,
        requiresAuth: true
    },
    {
        path: "/terms-of-service",
        element: <TermsOfService />,
        requiresAuth: false
    },
    {
        path: "/onboarding/teacher",
        element: <TeacherOnboarding />,
        requiresAuth: false
    },
    {
        path: "/onboarding/school",
        element: <SchoolOnboarding />,
        requiresAuth: false
    },
    {
        path: "/blog/learning-gaps",
        element: <LearningGapsBlog />,
        requiresAuth: false
    },
    {
        path: "/marking/:onlineTestId/:examId/:cAId",
        element: <Marking />,
        requiresAuth: true
    },
    {
        path: "/create-assessment",
        element: <CreateAssessmentPaperStep />,
        requiresAuth: true
    },
    {
        path: "/analysis/:caId",
        element: <Analysis />,
        requiresAuth: true,
    },
];

const routes = [
    {
        path: "/assessment-details",
        element: <CreateAssessmentWizard />,
        requiresAuth: true
    },
    {
        path: "/assessments",
        element: <ExamTestScreen />,
        requiresAuth: true
    },
    {
        path: "/assessment-paper",
        element: <CreateAssessmentPaperStep />,
        requiresAuth: true
    },
    {
        path: "/review-tests/:cAId",
        element: <ReviewAIMarking />,
        requiresAuth: true
    },
    {
        path: "/feedback/:onlineTestId/:name",
        element: <FeedbackPage />,
        requiresAuth: false
    },

    {
        path: "/home",
        element: <Home />,
        requiresAuth: true
    },
    {
        path: "/profile",
        element: <ProfileEdit />,
        requiresAuth: true
    },
    {
        path: "/add-announcement",
        element: <CreateAnnouncement />,
        requiresAuth: true
    },
    {
        path: "/manage-subjects-topics",
        element: <ManageSubjectsTopics />,
        requiresAuth: true
    },
    {
        path: "/manage-learners",
        element: <ManageLearners />,
        requiresAuth: true
    },
    {
        path: "/manage-courses",
        element: <ManageCourses />,
        requiresAuth: true
    },
    {
        path: "/help",
        element: <Help />,
        requiresAuth: false
    },
    {
        path: "/metrics",
        element: <Metrics />,
        requiresAuth: true
    },
    {
        path: "/upload-scripts",
        element: <UploadScripts />,
        requiresAuth: true
    },
    {
        path: "/assessment-tools",
        element: <AssessmentTools />,
        requiresAuth: true
    },
    {
        path: "/marking/get-submitted-tests/:examId/:courseId/:cAId",
        element: <MarkingClassList />,
        requiresAuth: true
    },
    {
        path: "/not-found",
        element: <NotFoundPage />,
        requiresAuth: false
    },
    {
        path: "/manage-learners/:courseId/:courseName",
        element: <ManageLearners />,
        requiresAuth: true
    },
    {
        path: "/upload-scripts/learners/:courseId/:subject/:grade/:assessmentId",
        element: <UploadLearnerScripts />,
        requiresAuth: true
    },
    {
        path: "/upload-scripts/assessment-details",
        element: <ScriptDetails />,
        requiresAuth: false
    },
    {
        path: "/get-assessment-course",
        element: <CourseAssessmentTable />,
        requiresAuth: true
    }
];

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Provider store={store}>
                <Router>
                    <Routes>
                        {authenticationRoutes.map((route) => {
                            return <Route
                                key={route.path}
                                path={route.path}
                                element={route.element}
                                requiresAuth={route.requiresAuth}
                            />
                        })}
                    </Routes>
                </Router>
            </Provider>
        </ThemeProvider>
    );
};

export default App;