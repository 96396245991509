import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import TextField from "@mui/material/TextField";
import { Box, Button, LinearProgress, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { OnlineTestApiService } from "../../../../services/api/OnlineTestAPIService";
import CourseAssessmentResultsApiService from "../../../../services/api/CourseAssessmentResultsApiService";
import Swal from "sweetalert2";

const MarkingClassList = () => {
    const { examId, courseId, cAId } = useParams();
    const [learners, setLearners] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    const pageSize = 15; // Set default page size

    useEffect(() => {
        const fetchLearners = async () => {
            try {
                const response = await OnlineTestApiService.fetchOnlineTestsByExamId(examId, courseId, token);
                if (response) {
                    // Map "Created" status to "Pending Marking"
                    const updatedLearners = response.learners.map((learner) => ({
                        ...learner,
                        status: learner.status === "Created" ? "Pending Marking" : learner.status,
                    }));
                    setLearners(updatedLearners);
                } else {
                    console.error("Failed to fetch learners.");
                }
            } catch (error) {
                console.error("Error fetching learners:", error);
            }
        };

        fetchLearners();
    }, [examId, courseId, token]);

    const handleRowClick = (params) => {
        if (params.row.attempted) {
            const onlineTestId = params.row.online_test_id;
            navigate(`/marking/${onlineTestId}/${examId}/${cAId}`, {
                state: {
                    learnerFirstName: params.row.first_name,
                    learnerLastName: params.row.last_name,
                },
            });
        }
    };

    const handleDoneMarking = async () => {
        try {
            // Update assessment stats
            const updateResponse = await CourseAssessmentResultsApiService.updateAssessmentStats(cAId, token);

            // Check if the response is successful (HTTP 200)
            if (updateResponse.message === "success") {
                // Show SweetAlert pop-up
                await Swal.fire({
                    title: "Success",
                    text: "Marking is done, and marks are updated!",
                    icon: "success",
                    confirmButtonText: "OK",
                });
            } else {
                // Handle non-200 responses
                await Swal.fire({
                    title: "Error",
                    text: "Failed to update stats. Please try again.",
                    icon: "error",
                    confirmButtonText: "OK",
                });
            }
        } catch (error) {
            console.error("Error during marking process:", error);

            // Show error SweetAlert pop-up
            await Swal.fire({
                title: "Error",
                text: "An error occurred during the marking process. Please try again.",
                icon: "error",
                confirmButtonText: "OK",
            });
        }
    };

    // Calculate learner statistics
    const completedCount = learners.filter((learner) => learner.status === "Completed").length;
    const notMarkedCount = learners.filter((learner) => learner.status === "Pending Marking").length;
    const notAttemptedCount = learners.filter((learner) => !learner.attempted).length;

    // Calculate progress bar values
    const totalLearners = learners.length;
    const progressCompleted = (completedCount / totalLearners) * 100;
    const progressNotMarked = (notMarkedCount / totalLearners) * 100;
    const progressNotAttempted = (notAttemptedCount / totalLearners) * 100;

    const columns = [
        {
            field: "name",
            headerName: "Learner Name",
            flex: 2,
            align: "left",
            renderCell: (params) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <span>{`${params.row.first_name} ${params.row.last_name}`}</span>
                </div>
            ),
        },
        {
            field: "attempted",
            headerName: "Attempted",
            flex: 1,
            align: "left",
            renderCell: (params) => <span>{params.row.attempted ? "Yes" : "No"}</span>,
        },
        {
            field: "status",
            headerName: "Marking Status",
            flex: 1,
            align: "left",
        },
    ];

    const filteredLearners = learners
        .filter(
            (learner) =>
                learner.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                learner.last_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                learner.status.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            // Sort by marking status: Pending Marking first
            const statusPriority = { "Pending Marking": 0, Completed: 1 };
            return statusPriority[a.status] - statusPriority[b.status];
        });

    return (
        <>
            <div style={{ display: "flex", flexDirection: "column", gap: "20px", padding: "20px" }}>
                {/* Inner flexbox (row layout for button on left and search on right) */}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: "20px",
                    }}
                >
                    <Button
                        variant="contained"
                        color="success"
                        sx={{
                            borderRadius: "25px",
                            backgroundColor: "#5f9ea0",
                            "&:hover": {
                                backgroundColor: "rgb(66, 110, 112)", // Hover color
                            },
                        }}
                        onClick={handleDoneMarking}
                    >
                        Done Marking
                    </Button>

                    <TextField
                        id="search"
                        placeholder="Search Learners"
                        size="small"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        variant="outlined"
                        InputProps={{
                            style: { width: "200px", borderRadius: 25 },
                        }}
                        sx={{
                            "& .MuiOutlinedInput-root": {
                                borderRadius: "25px",
                                borderColor: "#d0d4d9",
                            },
                            "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#d0d4d9",
                            },
                            "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#b3b3b3",
                            },
                            "& .MuiInputBase-input": {
                                padding: "10px",
                                color: "#5f9ea0",
                            },
                        }}
                    />
                </div>

                {/* Progress Bar with Labels (Horizontal Layout) */}
                <Box sx={{ width: "100%", marginBottom: "10px" }}>
                    <Typography variant="body2" sx={{ marginBottom: "5px", color: "#5f9ea0" }}>
                        Marking Progress
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="body2" sx={{ color: "#4a90e2" }}>
                                Completed ({completedCount})
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progressCompleted}
                                sx={{
                                    height: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#e0e0e0",
                                    "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#4a90e2", // Blue for completed
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="body2" sx={{ color: "#f5a623" }}>
                                Pending Marking ({notMarkedCount})
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progressNotMarked}
                                sx={{
                                    height: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#e0e0e0",
                                    "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#f5a623",
                                    },
                                }}
                            />
                        </Box>
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="body2" sx={{ color: "#d0021b" }}>
                                Not Attempted ({notAttemptedCount})
                            </Typography>
                            <LinearProgress
                                variant="determinate"
                                value={progressNotAttempted}
                                sx={{
                                    height: "10px",
                                    borderRadius: "5px",
                                    backgroundColor: "#e0e0e0",
                                    "& .MuiLinearProgress-bar": {
                                        backgroundColor: "#d0021b", // Red for not attempted
                                    },
                                }}
                            />
                        </Box>
                    </Box>
                </Box>

                {/* Table is below the search, class info, and button */}
                <div style={{ height: "35%", width: "100%" }}>
                    <DataGrid
                        rows={filteredLearners.map((learner) => ({ ...learner, id: learner.id }))}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[15, 30, 50]} // Add more options for pagination
                        pagination
                        disableSelectionOnClick
                        onRowClick={handleRowClick}
                        getRowClassName={(params) => (params.row.attempted ? "" : "greyed-out-row")}
                        sx={{
                            backgroundColor: "white",
                            borderRadius: "10px",
                            boxShadow: 0,
                            border: "1px solid #d0d4d9",  // Light border around the grid
                            [`& .${"MuiDataGrid-cell"}:focus-within`]: {
                                outline: "none",
                            },
                            "& .MuiDataGrid-cell": {
                                borderBottom: "1px solid #d0d4d9",  // Add a light border between rows
                                padding: "20px",  // Keep padding for cell content
                                whiteSpace: "nowrap",  // Prevent text wrapping
                                overflow: "hidden",  // Hide overflowed text
                                textOverflow: "ellipsis",  // Show ellipsis for long text
                            },
                            "& .MuiDataGrid-columnHeaders": {
                                backgroundColor: "#f3f7f8",  // Set the desired background color for column headers
                                borderBottom: "1px solid #d0d4d9",  // Add bottom border for column headers
                            },
                            "& .MuiDataGrid-columnHeaderTitle": {
                                color: "#555f67",  // Set text color to match the style you requested
                                fontWeight: "bold",  // Make the header text bold
                            },
                            "& .MuiDataGrid-columnSeparator--sideRight, & .MuiDataGrid-iconButtonContainer": {
                                display: "none", // Hides the column menu button and vertical lines
                            },
                            "& .MuiDataGrid-sortIcon": {
                                display: "none", // Hides the sort icon
                            },
                            // Add hover effect for rows
                            "& .MuiDataGrid-row:hover": {
                                backgroundColor: "#dce7ea",  // Set a darker background color on hover
                            },
                        }}
                    />
                </div>
            </div>
        </>
    );
};

export default MarkingClassList;