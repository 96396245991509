import { Link } from "react-router-dom";
import React, { useState } from "react";
import { IconButton, ListItemIcon, ListItemText, MenuItem, MenuList, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EducaseLogoLight from "../../assets/educase-logo-light2.png";
import "./navBar.css";
import HomeIconImage from "../../assets/home.png";
import ViewMetrics from "../../assets/report.png";
import ViewAssessments from "../../assets/viewassessments.png";
import { useDispatch, useSelector } from "react-redux";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import { setMobileIsNavBarVisible } from "../../reducers/componentStates";

const NavBar = () => {
    const dispatch = useDispatch();
    const [activeItem, setActiveItem] = useState(null);
    const [isMarkingOpen, setIsMarkingOpen] = useState(false);
    const [isMobile] = useState(window.innerWidth <= 700);
    const isMobileNavBarVisible = useSelector((state) => state.componentStates.isMobileNavBarVisible);

    const userInfo = JSON.parse(localStorage.getItem('user') || '{}');
    const userRole = userInfo.user_role || '';

    const handleMenuItemClick = (menuItem) => {
        dispatch(setMobileIsNavBarVisible(false));
        setActiveItem(menuItem);
    };

    if (isMobile && !isMobileNavBarVisible) {
        return null;
    }

    return (
        <nav className={"nav-bar"} tabIndex={0}>
            <div className="nav-bar-header">
                <img src={EducaseLogoLight} alt="Company Logo" className="nav-logo" />
            </div>
            <MenuList className="menu-list">
                <div className={"close-navbar-home-button"}>
                    <Link to="/home" style={{ color: "#4a606e", textDecoration: "none", flex: 1 }}>
                        <MenuItem className={`menu-item ${activeItem === "/" ? "active-item" : ""}`}
                                  onClick={() => handleMenuItemClick("/")}>
                            <ListItemIcon>
                                <img src={HomeIconImage} alt="Home" className="icon"
                                     style={{ width: "20px", height: "20px" }} />
                            </ListItemIcon>
                            <ListItemText>Home</ListItemText>
                        </MenuItem>
                    </Link>
                    {isMobile && (
                        <IconButton className="close-nav-button"
                                    onClick={() => dispatch(setMobileIsNavBarVisible(false))}>
                            <span>X</span>
                        </IconButton>
                    )}
                </div>

                <Link to="/assessments" style={{ color: "#4a606e", textDecoration: "none" }}>
                    <MenuItem className={`menu-item ${activeItem === "/assessments" ? "active-item" : ""}`}
                              onClick={() => handleMenuItemClick("/assessments")}>
                        <ListItemIcon>
                            <img src={ViewAssessments} alt="ViewAllAssessments" className="icon"
                                 style={{ width: "20px", height: "20px" }} />
                        </ListItemIcon>
                        <ListItemText>Assessments</ListItemText>
                    </MenuItem>
                </Link>


            <Link to="/upload-scripts" style={{ color: "#4a606e", textDecoration: "none" }}>
                <MenuItem className={`menu-item ${activeItem === "/upload-scripts" ? "active-item" : ""}`} onClick={() => handleMenuItemClick("/upload-scripts")}>
                    <ListItemIcon>
                        <AssignmentTurnedInIcon style={{ color: "#4c63af" }} /> {/* Marking icon */}
                    </ListItemIcon>
                    <ListItemText>Marking Submissions</ListItemText>
                </MenuItem>
            </Link>

                {!isMobile && (userRole === "admin" || userRole === "marker") && (
                    <Link to="/get-assessment-course" style={{ color: "#4a606e", textDecoration: "none" }}>
                        <MenuItem
                            className={`menu-item ${activeItem === "/get-assessment-course" ? "active-item" : ""}`}
                            onClick={() => handleMenuItemClick("/get-assessment-course")}>
                            <ListItemIcon>
                                <CheckCircleIcon style={{ color: "#4CAF50" }} />
                            </ListItemIcon>
                            <ListItemText>Marking Requests</ListItemText>
                        </MenuItem>
                    </Link>
                )}
                <Link
                    to="/get-assessment-course?status=completed"
                    style={{ color: "#4a606e", textDecoration: "none" }}
                >
                    <MenuItem
                        className={`menu-item ${activeItem === "/get-assessment-course?status=completed" ? "active-item" : ""}`}
                        onClick={() => handleMenuItemClick("/get-assessment-course?status=completed")}
                    >
                        <ListItemIcon>
                            <img
                                src={ViewMetrics}
                                alt="ViewMetrics"
                                className="icon"
                                style={{ width: "20px", height: "20px" }}
                            />
                        </ListItemIcon>
                        <ListItemText>Results & Insights</ListItemText>
                    </MenuItem>
                </Link>
            </MenuList>

            <div className="nav-bar-footer"
                 style={{ padding: "10px", marginTop: "auto", textAlign: "center", color: "#4a606e" }}>
                <Typography variant="body2" component="div">
                    Copyright © 2024 Educase
                </Typography>
            </div>
        </nav>
    );
};

export default NavBar;
