import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Divider, List, ListItem, ListItemText, Typography } from "@mui/material";
import { styled } from "@mui/system";
import AssessmentAPIService from "../../../services/api/AssessmentAPIService";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import SchoolIcon from "@mui/icons-material/School";
import RuleIcon from "@mui/icons-material/Rule";
import InfoIcon from "@mui/icons-material/Info";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";

const TestInstructionsPage = () => {
    const location = useLocation();
    const userInfo = localStorage.getItem("user");
    const expiryDate = localStorage.getItem("expiryDate");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;
    const query = new URLSearchParams(location.search);
    const assessmentId = query.get('assessment_id');
    const navigate = useNavigate();

    const [assessmentData, setAssessmentData] = useState(null);
    const [expiryStatus, setExpiryStatus] = useState("");

    useEffect(() => {
        
        const checkExpiryStatus = () => {
        const currentTime = dayjs();
        const expiryTime = dayjs(expiryDate);

        if (currentTime.isAfter(expiryTime)) {
            setExpiryStatus("The test has expired.");
        } else {
            const timeLeft = expiryTime.diff(currentTime);
            const hoursLeft = Math.floor(timeLeft / (1000 * 60 * 60));
            const minutesLeft = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            setExpiryStatus(`You have ${hoursLeft} hours and ${minutesLeft} minutes left to take the test.`);
        }
    };
        const fetchAssessmentData = async () => {
            try {
                const assessmentResponse = await AssessmentAPIService.fetchAssessment(assessmentId, token);
                if (assessmentResponse) {
                    setAssessmentData(assessmentResponse);
                    checkExpiryStatus();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Failed to fetch assessment data. Please try again later.'
                    });
                }
            } catch (error) {
                console.error('Error fetching assessment:', error);
                await Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: `Failed to fetch assessment data: ${error.message}`
                });
            }
        };

        if (assessmentId) {
            fetchAssessmentData();
        }
    }, [assessmentId, expiryDate, token]);

    const handleStartTest = async () => {
    try {
        // Call the createOnlineTest API
        const learnerId = parsedUserInfo.learnerId;  // Assuming you have the learnerId stored in the user info
        const response = await OnlineTestApiService.createOnlineTest(learnerId, assessmentId, token); // 60 minutes duration by default

        if (response && response.online_test_id) {
            // Successfully created or retrieved the test, now navigate to the assessment page
            navigate(`/assessment?assessment_id=${assessmentId}&online_test_id=${response.online_test_id}`, {
                state: {
                    answer_urls: response.answer_urls || [],
                }
            });
        } else {
            await Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Failed to start the test. Please try again later.'
            });
        }
    } catch (error) {
        console.error('Error starting the test:', error);
        await Swal.fire({
            icon: 'error',
            title: 'Error',
            text: `Failed to start the test: ${error.message}`
        });
    }
};


    return (
        <Box className="test-instructions-container" sx={{ bgcolor: 'floralwhite', p: 3, borderRadius: 2, boxShadow: 3 }}>
            <Typography variant="h4" gutterBottom color="primary" sx={{ textAlign: 'center' }}>
                Test Instructions
            </Typography>

            {assessmentData && (
                <>
                    <InstructionSection>
                        <SchoolIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Exam Name: {assessmentData.name}</Typography>
                        <Typography>Grade: {assessmentData.grade}</Typography>
                        <Typography>Assessment Type: {assessmentData.type}</Typography>
                    </InstructionSection>

                    <Divider sx={{ my: 3 }} />

                    <InstructionSection>
                        <InfoIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Materials Allowed</Typography>
                        <List dense>
                            <ListItem>
                                <ListItemText primary="Your textbook" />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="A calculator" />
                            </ListItem>
                        </List>
                    </InstructionSection>

                    <Divider sx={{ my: 3 }} />

                    <InstructionSection>
                        <Typography variant="h6">Questions</Typography>
                        <Typography>You will see <b>one question at a time</b>. Each question must be answered on a <b>new
                            page</b>.</Typography>
                    </InstructionSection>

                    <Divider sx={{ my: 3 }} />

                    <InstructionSection>
                        <Typography variant="h6">Help</Typography>
                        <Typography>If you have trouble, please call <b>Mathopa Crocker Letlalo</b> at <b>0813059081</b>.</Typography>
                    </InstructionSection>

                    <Divider sx={{ my: 3 }} />

                    <InstructionSection>
                        <RuleIcon sx={{ mr: 1 }} />
                        <Typography variant="h6">Rules</Typography>
                        <List dense>
                            <ListItem>
                                <ListItemText primary="Do not get help from others." />
                            </ListItem>
                            <ListItem>
                                <ListItemText primary="Do not copy or share questions or answers." />
                            </ListItem>
                        </List>
                    </InstructionSection>

                    <Divider sx={{ my: 3 }} />

                    <InstructionSection>
                        <Typography variant="h6">Agreement</Typography>
                        <Typography>By taking this test, you agree to follow all the rules.</Typography>
                    </InstructionSection>

                    <Divider sx={{ my: 3 }} />

                    <InstructionSection>
                        <Typography variant="h6">Test Status</Typography>
                        <Typography>{expiryStatus}</Typography>
                    </InstructionSection>

                    {expiryStatus === "The test has expired." ? null : (
                        <Box textAlign="center" sx={{ mt: 4 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleStartTest}
                                sx={{
                                    boxShadow: 2,
                                    borderRadius: '20px',
                                    textTransform: 'none',
                                    px: 5,
                                    py: 1.5
                                }}
                            >
                                Start Test
                            </Button>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
};

const InstructionSection = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export default TestInstructionsPage;