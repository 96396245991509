import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { OnlineTestApiService } from "../../../services/api/OnlineTestAPIService";
import { FeedbackApiService } from "../../../services/api/FeedbackApiService";
import Swal from "sweetalert2";
import Rubric from "./MarkingMenu/Rubric/Rubric";
import QuestionSelector from "./MarkingMenu/QuestionSelector/QuestionSelector";
import ImageViewer from "./MarkingMenu/ImageViewer";

const Marking = () => {
    // const [pdfUrl, setPdfUrl] = useState("");
    const [imageUrls, setImageUrls] = useState([]);
    const [questions, setQuestions] = useState([]);
    const { examId, onlineTestId, cAId } = useParams();
    const [allocatedMarks, setAllocatedMarks] = useState({});
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
    const [grade, setGrade] = useState("");
    const [subject, setSubject] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [assessmentName, setAssessmentName] = useState("");
    const [answerPdfUrl, setAnswerPdfUrl] = useState("");
    const [learnerAnswers, setLearnerAnswers] = useState({});

    const navigate = useNavigate();

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    // Function to handle mark changes
    const handleMarkChange = (id, type, marks) => {
        const key = `${type}_${id}`;
        setAllocatedMarks((prevState) => ({
            ...prevState,
            [key]: { type, allocated_marks: marks !== "" ? parseFloat(marks) : 0 },
        }));
    };

    // Function to handle learner answer changes
    const handleLearnerAnswerChange = (id, type, learnerAnswer) => {
        const key = `${type}_${id}`;
        setLearnerAnswers((prevState) => ({
            ...prevState,
            [key]: learnerAnswer,
        }));
    };

    const handleSubmitMarking = async () => {
        const feedbackList = questions.flatMap((question) => {
            const feedback = [];
            // (A) If the question has NO subquestions, push question-level feedback
            if (!question.sub_questions || question.sub_questions.length === 0) {
                const questionKey = `question_${question.id}`;
                feedback.push({
                    question_id: question.id,
                    type: "question",
                    allocated_marks: allocatedMarks[questionKey]?.allocated_marks ?? 0,
                    learner_answer: learnerAnswers[questionKey] || "",
                    skipped: allocatedMarks[questionKey]?.skipped || false,
                });
            }
            // (B) Otherwise, loop each subquestion
            else {
                question.sub_questions.forEach((subQuestion) => {
                    // If the subquestion has NO nested subquestions, push subquestion feedback
                    if (!subQuestion.nested_sub_questions || subQuestion.nested_sub_questions.length === 0) {
                        const subQuestionKey = `subquestion_${subQuestion.id}`;
                        feedback.push({
                            question_id: subQuestion.id,
                            type: "subquestion",
                            allocated_marks: allocatedMarks[subQuestionKey]?.allocated_marks ?? 0,
                            learner_answer: learnerAnswers[subQuestionKey] || "",
                            skipped: allocatedMarks[subQuestionKey]?.skipped || false,
                        });
                    }
                    // Otherwise, push only nested_subquestion feedback
                    else {
                        subQuestion.nested_sub_questions.forEach((nested) => {
                            const nestedKey = `nested_subquestion_${nested.id}`;
                            feedback.push({
                                question_id: nested.id,
                                type: "nested_subquestion",
                                allocated_marks: allocatedMarks[nestedKey]?.allocated_marks ?? 0,
                                learner_answer: learnerAnswers[nestedKey] || "",
                                skipped: allocatedMarks[nestedKey]?.skipped || false,
                            });
                        });
                    }
                });
            }

            return feedback;
        });

        try {
            const response = await FeedbackApiService.submitManualFeedback(
                onlineTestId,
                feedbackList,
                cAId,
                answerPdfUrl,
                token,
            );

            if (response) {
                await Swal.fire("Success", "Manual feedback submitted successfully!", "success");
                navigate(-1); // Navigate back after successful submission
            } else {
                await Swal.fire("Error", "Failed to submit manual feedback. Please try again.", "error");
            }
        } catch (error) {
            console.error("Error submitting manual feedback:", error);
            await Swal.fire("Error", "An error occurred while submitting feedback.", "error");
        }
    };

    const handleHomeClick = () => {
        // Navigate back to home or any other page
        navigate("/home");
    };

    useEffect(() => {
        const fetchImageUrls = async () => {
            try {
                const response = await OnlineTestApiService.fetchImageUrlsByOnlineTestId(onlineTestId, token);
                if (response) {
                    setQuestions(response.questions);
                    setImageUrls(response.image_urls);
                    setGrade(response.grade);
                    setSubject(response.subject);
                    setFirstName(response.first_name);
                    setLastName(response.last_name);
                    setAssessmentName(response.exam_name);

                    // Initialize allocated marks and learner answers from backend feedback
                    const initialMarks = {};
                    const initialLearnerAnswers = {};

                    // Helper function to create a composite key
                    const getCompositeKey = (fb) => `${fb.question_type}_${fb.question_id}`;

                    // Loop through feedback to populate marks and learner answers if available
                    response.feedback.forEach((fb) => {
                        const key = getCompositeKey(fb);

                        // Populate initial marks
                        initialMarks[key] = {
                            type: fb.question_type,
                            allocated_marks: fb.allocated_marks !== null ? fb.allocated_marks : 0,
                            skipped: fb.skipped || false, // Populate skipped state
                        };

                        // Populate initial learner answers
                        if (fb.learner_answer !== null) {
                            initialLearnerAnswers[key] = fb.learner_answer;
                        }
                    });

                    // Ensure subquestions and nested subquestions are handled correctly
                    response.questions.forEach((question) => {
                        const questionKey = `question_${question.id}`;

                        // Initialize learner answers for questions if not already done
                        if (!initialLearnerAnswers[questionKey]) {
                            initialLearnerAnswers[questionKey] = "";
                        }

                        question.sub_questions?.forEach((subQuestion) => {
                            const subQuestionKey = `subquestion_${subQuestion.id}`;
                            if (!initialLearnerAnswers[subQuestionKey]) {
                                initialLearnerAnswers[subQuestionKey] = "";
                            }

                            subQuestion.nested_sub_questions?.forEach((nestedSubQuestion) => {
                                const nestedSubQuestionKey = `nested_subquestion_${nestedSubQuestion.id}`;
                                if (!initialLearnerAnswers[nestedSubQuestionKey]) {
                                    initialLearnerAnswers[nestedSubQuestionKey] = "";
                                }
                            });
                        });
                    });

                    setAllocatedMarks(initialMarks);
                    setLearnerAnswers(initialLearnerAnswers);
                } else {
                    console.error("Failed to fetch image URLs.");
                }
            } catch (error) {
                console.error("Error fetching image URLs:", error);
            }
        };

        fetchImageUrls();
    }, [examId, onlineTestId, token]);


    const handlePdfSaved = (url) => {
        setAnswerPdfUrl(url); // Store the saved PDF URL
    };

    const totalAllocatedMarks = parseFloat(
        Object.values(allocatedMarks).reduce((sum, mark) => {
            const allocated = parseFloat(mark?.allocated_marks) || 0;
            return sum + allocated;
        }, 0),
    ).toFixed(2);

    // Function to calculate total possible marks
    const totalPossibleMarks = questions.reduce(
        (sum, question) => sum + (parseFloat(question.marks) || 0),
        0,
    );

    // Handle Skipped Toggle
    const handleSkippedToggle = (id, type) => {
        const key = `${type}_${id}`;
        setAllocatedMarks((prevState) => ({
            ...prevState,
            [key]: { ...prevState[key], skipped: !prevState[key]?.skipped },
        }));
    };

    const extractUrl = (url) => {
        // Handle array-like string: ['https://...']
        const match = url.match(/'(.*?)'/);
        return match ? match[1] : url;
    };

    return (
        <div className="manual-marking-container" style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            {/* Question Selector Component */}
            <QuestionSelector
                questions={questions}
                selectedQuestionIndex={selectedQuestionIndex}
                setSelectedQuestionIndex={setSelectedQuestionIndex}
                onHomeClick={handleHomeClick}
                onSubmitMarking={handleSubmitMarking}
                grade={grade}
                subject={subject}
                firstName={firstName}
                lastName={lastName}
                assessmentName={assessmentName}
                totalAllocatedMarks={totalAllocatedMarks} // Pass calculated marks
                totalPossibleMarks={totalPossibleMarks}
            />

            {/* Main Content Area */}
            <div className="marking-content" style={{ display: "flex", flex: 1 }}>
                {/* Left - Rubric */}
                <div className="rubric-section"
                     style={{ width: "40%", padding: "20px", borderRight: "1px solid #ccc" }}>
                    {questions[selectedQuestionIndex] && (
                        <Rubric
                            question={questions[selectedQuestionIndex]}
                            index={selectedQuestionIndex}
                            onMarkChange={handleMarkChange}
                            onLearnerAnswerChange={handleLearnerAnswerChange}
                            allocatedMarks={allocatedMarks}
                            learnerAnswers={learnerAnswers}
                            handleSkippedToggle={handleSkippedToggle}
                        />
                    )}
                </div>

                {/* Right - PDF Viewer */}
                <div className="pdf-viewer-section" style={{ width: "60%", padding: "20px" }}>
                    {imageUrls !== "" ? (
                        <ImageViewer imageUrls={imageUrls} />
                    ) : (
                        <p>Loading...</p>
                    )}

                    {/*           /!* Right - PDF Viewer *!/*/}
                    {/*<div className="pdf-viewer-section" style={{ width: "60%", padding: "20px" }}>*/}
                    {/*    {pdfUrl !== "" ? (*/}
                    {/*        <AdobePDFViewer pdfUrl={pdfUrl} onPdfSaved={handlePdfSaved} />*/}
                    {/*    ) : (*/}
                    {/*        <p>Loading...</p>*/}
                    {/*    )}*/}
                </div>
            </div>
        </div>
    );
};

export default Marking;
