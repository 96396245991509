import React, { useState } from "react";
import "./testWidget.css";
import { format, formatDistanceToNow, parseISO } from "date-fns";
import { Avatar, Divider, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, useMediaQuery } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import LinkIcon from "@mui/icons-material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import AssessmentAPIService from "../../services/api/AssessmentAPIService";
import Swal from "sweetalert2";
import { useRollbar } from "@rollbar/react";
import CopyTestLinkDialog from "../../components/CopyTestLinkDialog/CopyTestLinkDialog";

const TestWidget = ({ testId, testName, grade, subject, lastUpdated, onDelete, handleEdit }) => {
  const rollbar = useRollbar(); // Initialize Rollbar
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false); // Use this to manage the CopyTestLinkDialog
  const [selectedAssessmentId, setSelectedAssessmentId] = useState(null); // Store the selected test ID
  const userInfo = localStorage.getItem("user");
  const parsedUserInfo = JSON.parse(userInfo || "{}");

  // Detect if the screen is mobile
  const isMobile = useMediaQuery("(max-width:600px)");

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenModal(false);
    setSelectedAssessmentId(null);
  };

  const handleDeleteTest = async () => {
    try {
      const { token } = parsedUserInfo;

      const response = await AssessmentAPIService.deleteAssessment(testId, token);
      if (response.ok) {
        onDelete(testId);
        Swal.fire({
          icon: 'success',
          title: 'Test deleted successfully',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      } else {
        rollbar.warning('Failed to delete assessment:', response.status); // Log warning with Rollbar
        console.error('Failed to delete assessment:', response.status);
      }
    } catch (error) {
      rollbar.error('Error deleting assessment:', error); // Log error with Rollbar
      console.error('Error deleting assessment:', error);
    } finally {
      handleClose();
    }
  };

  const handleShare = (event) => {
    event.stopPropagation(); // Prevent double-click from triggering edit
    setSelectedAssessmentId(testId); // Set the selected test ID
    setOpenModal(true); // Open the CopyTestLinkDialog
  };


  const formattedLastUpdated = (lastUpdated) => {
    const lastUpdatedDate = parseISO(lastUpdated);
    const now = new Date();
    const daysDifference = (now - lastUpdatedDate) / (1000 * 60 * 60 * 24);

    if (daysDifference > 15) {
      return format(lastUpdatedDate, 'yyyy-MM-dd HH:mm');
    } else {
      return formatDistanceToNow(lastUpdatedDate, { addSuffix: true });
    }
  };

  return (
      <div className="test-widget">
      <div className="test-header">
        <div className="test-details">
          <Avatar
            className="test-avatar"
            sx={{
              width: 40,
              height: 40,
              bgcolor: 'primary',
              color: 'white',
              fontWeight: 'bold',
              marginRight: 2,
            }}
          >
            {testName.charAt(0)}
          </Avatar>
          <div className="test-title">
            <div className="test-name">{testName}</div>
            <div className="test-info">{`Grade ${grade} • ${subject}`}</div>
          </div>
        </div>
      </div>
      <IconButton className="more-options" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className="test-options-menu"
      >
        <div className="menu-header">
          <div className="menu-header-title">{testName}</div>
          <div className="menu-header-info">{`${subject} • ${grade} • Last updated: ${formattedLastUpdated(lastUpdated)}`}</div>
        </div>
        <Divider />
        <MenuItem onClick={() => handleEdit(testId)}>
          <ListItemIcon>
            <EditIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Edit Assessment" />
        </MenuItem>

        {/* Conditionally render "Copy Online Test Link" if not on mobile */}
        {!isMobile && (
            <MenuItem onClick={(event) => handleShare(event)}>
              <ListItemIcon>
                <LinkIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Copy Online Test Link" />
            </MenuItem>

        )}

        <MenuItem onClick={handleDeleteTest}>
          <ListItemIcon>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Delete" />
        </MenuItem>
      </Menu>

      {/* Use CopyTestLinkDialog to handle sharing */}
      <CopyTestLinkDialog
        open={openModal}
        onClose={handleClose}
        selectedAssessmentId={selectedAssessmentId}
        parsedUserInfo={parsedUserInfo} // Pass parsed user info
      />
    </div>
  );
};

export default TestWidget;
