import React, { useState } from "react";
import {
    Avatar,
    Divider,
    IconButton,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Tooltip,
    Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import SchoolIcon from "@mui/icons-material/School";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Swal from "sweetalert2";
import "./header.css";
import getConfig from "../../config";
import { setMobileIsNavBarVisible } from "../../reducers/componentStates";

const Header = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState(null);
    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token, profilePic, user_role, first_name, last_name, email } = parsedUserInfo; // Extract email
    const [isMobile] = useState(window.innerWidth <= 700);

    const handleHomeClick = () => {
        dispatch(setMobileIsNavBarVisible(true));
    };

    const handleLogout = async () => {
        try {
            const config = getConfig();
            const response = await fetch(`${config.api.base_url}/logout/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Token ${token}`,
                },
            });
            if (response.ok) {
                navigate('/auth/login');
            } else {
                await Toast.fire({
                    icon: "error",
                    title: "Logout failed, please try again.",
                });
            }
        } catch (error) {
            await Toast.fire({
                icon: "error",
                title: "An error occurred. Please try again later.",
            });
        }
    };

    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
    });

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleProfileMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <header className="app-header">
            <div className="header-content">
                <div className="header-left">
                    {isMobile && (
                        <IconButton className="home-icon" onClick={handleHomeClick}>
                            <HomeIcon />
                        </IconButton>
                    )}
                </div>

                <div className="header-right">
                    {/* Display Logged-in User Info */}
                    {first_name && last_name && (
                        <Typography variant="body1" style={{ marginRight: "15px" }}>
                            Logged in as: <strong>{first_name} {last_name} ({user_role})</strong>
                        </Typography>
                    )}

                    {/* Notifications */}
                    <Tooltip title="Notifications">
                        <IconButton className="header-icon">
                            <NotificationsIcon />
                        </IconButton>
                    </Tooltip>

                    {/* Profile Avatar */}
                    <Avatar
                        src={profilePic}
                        alt="Profile Picture"
                        className="header-avatar"
                        onClick={handleProfileMenuOpen}
                    />

                    {/* Profile Menu */}
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleProfileMenuClose}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <MenuList>
                            {/* Profile Link */}
                            <Link to="/profile" style={{ color: "#4a606e", textDecoration: "none" }}>
                                <MenuItem onClick={handleProfileMenuClose}>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText>Profile</ListItemText>
                                </MenuItem>
                            </Link>

                            {/* Admin Restricted Options */}
                            {user_role === "admin" && (
                                <Link to="/manage-subjects-topics" style={{ color: "#4a606e", textDecoration: "none" }}>
                                    <MenuItem onClick={handleProfileMenuClose}>
                                        <ListItemIcon><SchoolIcon /></ListItemIcon>
                                        <ListItemText>Manage Subjects & Topics</ListItemText>
                                    </MenuItem>
                                </Link>
                            )}

                            {/* Accessible for All */}
                            <Link to="/manage-courses" style={{ color: "#4a606e", textDecoration: "none" }}>
                                <MenuItem onClick={handleProfileMenuClose}>
                                    <ListItemIcon><SchoolIcon /></ListItemIcon>
                                    <ListItemText>Manage Courses and Learners</ListItemText>
                                </MenuItem>
                            </Link>

                            {/* Add Announcement for Admin */}
                            {user_role === "admin" && (
                                <Link to="/add-announcement" style={{ color: "#4a606e", textDecoration: "none" }}>
                                    <MenuItem onClick={handleProfileMenuClose}>
                                        <ListItemIcon><AnnouncementIcon /></ListItemIcon>
                                        <ListItemText>Add Announcement</ListItemText>
                                    </MenuItem>
                                </Link>
                            )}

                            <Divider style={{ margin: "10px 0" }} />

                            {/* Show Email */}
                            {email && (
                                <MenuItem>
                                    <ListItemIcon><PersonIcon /></ListItemIcon>
                                    <ListItemText>
                                        <Typography variant="caption" color="textSecondary">
                                            {email}
                                        </Typography>
                                    </ListItemText>
                                </MenuItem>
                            )}

                            {/* Sign Out */}
                            <MenuItem onClick={() => { handleProfileMenuClose(); handleLogout(); }}>
                                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                                <ListItemText>Sign out</ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </div>
            </div>
        </header>
    );
};

export default Header;
