import getConfig from "../../config/index";
import Swal from "sweetalert2";

export const AuthenticationAPIService = {
    async register(userData) {
        const config = getConfig();
        try {
            const response = await fetch(`${config.api.base_url}/register/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(userData),
            });

            if (response.ok) {
                return await response;
            } else {
                const data = await response;
                throw new Error(data.error);
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async login(credentials) {
        const config = getConfig();
        try {
            const response = await fetch(`${config.api.base_url}/login/`, {
                method: "POST",
                body: JSON.stringify(credentials),
                headers: { "Content-Type": "application/json" },
            });

            if (response.ok) {
                return response; // Login successful
            } else {
                const errorData = await response.json(); // Parse the response body as JSON

                // Explicitly handle errors using SweetAlert2
                if (errorData.error === "Incorrect password") {
                    Swal.fire({
                        icon: "error",
                        title: "Incorrect Password",
                        text: "The password you entered is incorrect. Try again!",
                        confirmButtonColor: "#00c1e4",
                    });
                    throw new Error("Incorrect password. Try again!");
                } else if (errorData.error === "User not found") {
                    Swal.fire({
                        icon: "error",
                        title: "Email Not Found",
                        text: "The email address entered does not exist.",
                        confirmButtonColor: "#00c1e4",
                    });
                    throw new Error("Email not found. Please check and try again.");
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Login Failed",
                        text: "Something went wrong. Please try again.",
                        confirmButtonColor: "#00c1e4",
                    });
                    throw new Error(errorData.error || "Login failed. Please try again.");
                }
            }
        } catch (error) {
            console.error("Error:", error);
            throw error;
        }
    },
    async forgotPassword(username) {
        const config = getConfig();
        try {
            const response = await fetch(`${config.api.base_url}/forgot-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username }),
            });

            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                const errorData = await response.json();
                throw new Error(errorData.error || 'Forgot password failed');
            }
        } catch (error) {
            console.error('Error:', error);
            throw error;
        }
    },

    async choosePassword(newPassword, confirmNewPassword, uidb64, token) {
        const config = getConfig();

        try {
            return await fetch(`${config.api.base_url}/choose-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    token: token,
                    uidb64: uidb64,
                    new_password: newPassword,
                    confirm_new_password: confirmNewPassword
                })
            });
        } catch (error) {
            console.error('Error', 'An unexpected error occurred', 'error');
        }
    },

async updateProfile(suburb, user_role, province, curriculum, organisationName, organisationType, token) {
    const config = getConfig();
    try {
        const response = await fetch(`${config.api.base_url}/user/update-profile/`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Token ${token}`,
            },
            body: JSON.stringify({
                suburb,
                user_role,
                province,
                curriculum,
                organisation_name: organisationName,
                organisation_type: organisationType
            }),
        });

        if (response.ok) {
            return await response.json();
        } else {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Profile update failed');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
    },
    /**
     * Validate Authentication Token
     */
    async validateToken() {
        const config = getConfig();
        const token = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).token : null;

        if (!token) {
            return false;
        }

        try {
            const response = await fetch(`${config.api.base_url}/validate-token/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Token ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                return data.token_valid; // Return true/false
            } else {
                return false;
            }
        } catch (error) {
            console.error("Error validating token:", error.message);
            return false;
        }
    }

}