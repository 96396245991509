import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, Chip, Typography } from "@mui/material";
import "../OnboardingPage.css";
import { useNavigate } from "react-router-dom";
import CourseApiService from "../../../services/api/courseAPIService";
import SubjectApiService from "../../../services/api/subjectAPIService";

const TeacherOnboarding = () => {
    const [selectedGrades, setSelectedGrades] = useState([]); // Store grades as objects with grade and selectedSubjects
    const [grades, setGrades] = useState([]); // Store available grades dynamically fetched
    const [subjects, setSubjects] = useState([]); // Store fetched subjects from the backend
    const [selectedClasses, setSelectedClasses] = useState({}); // Store selected classes by subject
    const [token, setToken] = useState('');
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState(false);

    const classes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H']; // Available classes

    useEffect(() => {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
            const user = JSON.parse(storedUser);
            setEmail(user.email || '');
            setToken(user.token);  // Set token for authentication
        }
    }, []);

    const fetchSubjects = async () => {
        const response = await SubjectApiService.fetchSubjects(true);
        if (response.ok) {
            const subjects = await response.json();
            setSubjects(subjects);

            // Extract all unique grades from the subjects
            const allGrades = [...new Set(subjects.flatMap(subject => subject.grades.map(grade => grade.grade)))]
                .sort((a, b) => a - b);  // Sort grades numerically
            setGrades(allGrades);
        }
    };

    useEffect(() => {
        fetchSubjects(); // Fetch subjects and grades when the component loads
    }, []);

    const handleGradeClick = (gradeValue) => {
        const gradeIndex = selectedGrades.findIndex((g) => g.grade === gradeValue);

        if (gradeIndex !== -1) {
            // Grade already selected, remove it
            const newSelectedGrades = selectedGrades.filter((g) => g.grade !== gradeValue);
            setSelectedGrades(newSelectedGrades);

            // Remove associated subjects and classes
            const newSelectedClasses = { ...selectedClasses };
            delete newSelectedClasses[gradeValue];
            setSelectedClasses(newSelectedClasses);
        } else {
            // Select the grade if less than 4 grades are selected
            if (selectedGrades.length < 4) {
                setSelectedGrades([...selectedGrades, { grade: gradeValue, selectedSubjects: [] }]);
            }
        }
    };

    const getSubjectsForGrade = (grade) => {
        return subjects
            .filter(subject => subject.grades.some(g => g.grade === grade))
            .map(subject => subject.name);
    };

    const handleSubjectToggle = (gradeValue, subject) => {
        const newSelectedGrades = [...selectedGrades];
        const gradeIndex = newSelectedGrades.findIndex((g) => g.grade === gradeValue);

        if (gradeIndex !== -1) {
            const selectedSubjects = newSelectedGrades[gradeIndex].selectedSubjects;

            if (selectedSubjects.includes(subject)) {
                newSelectedGrades[gradeIndex].selectedSubjects = selectedSubjects.filter(s => s !== subject);
            } else if (selectedSubjects.length < 4) {
                newSelectedGrades[gradeIndex].selectedSubjects = [...selectedSubjects, subject];
            }

            setSelectedGrades(newSelectedGrades);
        }
    };

    const handleClassToggle = (gradeValue, subject, classValue) => {
        setSelectedClasses(prev => {
            const currentClasses = prev[`${gradeValue}_${subject}`] || [];
            const isClassSelected = currentClasses.includes(classValue);

            return {
                ...prev,
                [`${gradeValue}_${subject}`]: isClassSelected
                    ? currentClasses.filter(c => c !== classValue) // Remove class if already selected
                    : [...currentClasses, classValue] // Add class if not already selected
            };
        });
    };

    const validateForm = () => {
        const allRequirementsMet = selectedGrades.length > 0 && selectedGrades.every(grade =>
            grade.selectedSubjects.length > 0 &&
            grade.selectedSubjects.every(subject =>
                selectedClasses[`${grade.grade}_${subject}`] && selectedClasses[`${grade.grade}_${subject}`].length > 0
            )
        );

        setErrors(!allRequirementsMet);
        return allRequirementsMet;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return; // Stop submission if form is invalid
        }

        const coursesPayload = selectedGrades
            .filter(grade => grade.selectedSubjects.length > 0)
            .flatMap(grade =>
                grade.selectedSubjects.map(subject => ({
                    grade: grade.grade,
                    subject: subject,
                    classes: selectedClasses[`${grade.grade}_${subject}`] || [],
                }))
            );

        try {
            const startDate = new Date().toISOString().split('T')[0];
            const courses = coursesPayload.flatMap(course =>
                course.classes.map(classValue => ({
                    course_name: `${course.subject} Grade ${course.grade} Class ${classValue}`,
                    grade: course.grade,
                    subject_name: course.subject,
                    group: classValue,
                    start_date: startDate,
                    end_date: startDate
                }))
            );

            await CourseApiService.createCourse(courses, token);
            navigate('/home');
        } catch (error) {
            console.error("Error creating courses:", error);
            alert("An error occurred while creating courses.");
        }
    };

    const renderSubjectChips = (grade) => {
        const subjectsForGrade = getSubjectsForGrade(grade);
        const selectedGrade = selectedGrades.find((g) => g.grade === grade) || { selectedSubjects: [] };
        return subjectsForGrade.map(subject => (
            <Chip
                key={subject}
                label={subject}
                clickable
                onClick={() => handleSubjectToggle(grade, subject)}
                color={selectedGrade.selectedSubjects.includes(subject) ? "primary" : "default"}
                disabled={selectedGrade.selectedSubjects.length >= 4 && !selectedGrade.selectedSubjects.includes(subject)}
            />
        ));
    };

    const renderClassChips = (gradeValue, subject) => {
        const selectedClassesForSubject = selectedClasses[`${gradeValue}_${subject}`] || [];
        return classes.map((classValue) => (
            <Chip
                key={classValue}
                label={`Class ${classValue}`}
                clickable
                onClick={() => handleClassToggle(gradeValue, subject, classValue)}
                color={selectedClassesForSubject.includes(classValue) ? "primary" : "default"}
            />
        ));
    };

    const handleBackClick = () => {
        navigate('/onboarding-page');
    };

    return (
        <div className="onboarding-card-container">
            <Card
                className="onboarding-card-educase"
                sx={{
                    borderRadius: { xs: '5px', sm: '10px', md: '30px' },
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    padding: { xs: '5px', sm: '30px', md: '40px' },
                    width: { xs: '100vw', sm: '100vw', md: '80vw' },
                    maxWidth: { xs: '100vw', sm: '100vw', md: '1200px' },
                    margin: { xs: '0px', sm: '30px', md: '40px' },
                    overflowY: 'auto',
                }}
            >
                <CardContent className="card-content-educase">
                    <p className="title-educase">
                        Perfect! Now let's choose subjects and grades
                    </p>
                    <p className="subtitle-educase">
                        You can select up to 4 grades and assign a maximum of 4 subjects for each grade.
                    </p>

                    <h3 className="input-label-educase">Add Grades with Their Associated Subjects</h3>

                    <Typography variant="h6" className="input-label-educase">
                        Select a grade to add subjects:
                    </Typography>

                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                        {grades.map((gradeValue) => (
                            <Chip
                                key={gradeValue}
                                label={`Grade ${gradeValue}`}
                                clickable
                                onClick={() => handleGradeClick(gradeValue)}
                                color={selectedGrades.some(g => g.grade === gradeValue) ? "primary" : "default"}
                            />
                        ))}
                    </Box>

                    {selectedGrades.map((gradeValue, index) => (
                        <Box key={index} sx={{ mt: 3 }}>
                            <Typography variant="h6" sx={{ mt: 2 }}>
                                {`${index + 1}. Add subjects for Grade ${gradeValue.grade}`}
                            </Typography>

                            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 2 }}>
                                {renderSubjectChips(gradeValue.grade)}
                            </Box>

                            {errors && gradeValue.selectedSubjects.length === 0 && (
                                <Typography className="error-text">Please select at least one subject.</Typography>
                            )}

                            {gradeValue.selectedSubjects.map((subject, subIndex) => (
                                <Box key={subIndex} sx={{ mt: 3 }}>
                                    <Typography variant="h6" sx={{ mt: 2 }}>
                                        {`Select Class for ${subject}`}
                                    </Typography>

                                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1, mt: 1 }}>
                                        {renderClassChips(gradeValue.grade, subject)}
                                    </Box>

                                    {errors && (!selectedClasses[`${gradeValue.grade}_${subject}`] ||
                                        selectedClasses[`${gradeValue.grade}_${subject}`].length === 0) && (
                                        <Typography className="error-text">Please select at least one class for this subject.</Typography>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    ))}

                    <Box mt={4} display="flex" justifyContent="space-between">
                        <Button
                            variant="outlined"
                            onClick={handleBackClick}
                            sx={{ color: "primary", width: "100px", fontSize: "16px", borderRadius: "16px" }}
                        >
                            Back
                        </Button>

                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            sx={{ backgroundColor: "primary", color: "white", width: "100px", fontSize: "16px", borderRadius: "16px" }}
                        >
                            Submit
                        </Button>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography variant="body3" sx={{ color: '#777', textAlign: 'left' }}>
                            You're signed in as {email}. <a href="/signout" style={{ color: '#31c0e8', textDecoration: 'none', fontSize: '13px' }}>Sign out</a>
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </div>
    );
};

export default TeacherOnboarding;
