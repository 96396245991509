import React, { useEffect, useState } from "react";
import "./home.css";
import { Box, Button, IconButton, Modal, Paper, Typography } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import TestWidget from "./testWidget";
import CircularProgress from "@mui/material/CircularProgress";
import AssessmentAPIService from "../../services/api/AssessmentAPIService";
import FeatureAnnouncementModal from "../FeatureAnnouncement/FeatureAnnouncement";
import { setIsCreateQuestionVisible } from "../../reducers/componentStates";
import Swal from "sweetalert2";
import { useDispatch } from "react-redux";
import { useRollbar } from "@rollbar/react";
import CourseAssessmentResultsApiService from "../../services/api/CourseAssessmentResultsApiService";
import DisplayContent from "../../components/displayContent/displayContent";
import CloseIcon from "@mui/icons-material/Close";

const Home = () => {
  const [recentlyWorkedOn, setRecentlyWorkedOn] = useState([]);
  const [baselineTests, setBaselineTests] = useState([]);
  const [upcomingTests, setUpcomingTests] = useState([]);
  const [markedTests, setMarkedTests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [announcements, setAnnouncements] = useState([]);
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);
  const [visibleWidgets, setVisibleWidgets] = useState(5);
  const [showAllMarkedTests, setShowAllMarkedTests] = useState(false);
  const dispatch = useDispatch();
  const rollbar = useRollbar();
  const navigate = useNavigate();
    const [analysisModalOpen, setAnalysisModalOpen] = useState(false);
  const [selectedTestAnalysis, setSelectedTestAnalysis] = useState(null)

  useEffect(() => {
    const updateVisibleWidgets = () => {
      if (window.innerWidth <= 2000) {
        setVisibleWidgets(3);
      } else {
        setVisibleWidgets(4);
      }
    };

    updateVisibleWidgets();

    window.addEventListener('resize', updateVisibleWidgets);

    return () => window.removeEventListener('resize', updateVisibleWidgets);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;

      const assessments = await AssessmentAPIService.shallowFetchAssessments(token);

      if (assessments) {
        const sortedByDate = assessments.sort((a, b) => new Date(b.last_updated) - new Date(a.last_updated));

        setRecentlyWorkedOn(sortedByDate.slice(0, visibleWidgets));
        setBaselineTests(assessments.filter(test => test.type === "baseline_test").slice(0, visibleWidgets));
        setUpcomingTests(assessments.sort((a, b) => new Date(b.assessment_date) - new Date(a.assessment_date)));
      }

      const announcementData = await AssessmentAPIService.fetchFeatureAnnouncements(token);
      if (announcementData && announcementData.length > 0) {
        setAnnouncements(announcementData);
        setModalOpen(true);
      }

      setLoading(false);
    };

    fetchData();
    dispatch(setIsCreateQuestionVisible(true));

  }, [visibleWidgets]);



  useEffect(() => {
    const fetchAllCourseAssessments = async () => {
      try {
        const userInfo = localStorage.getItem("user");
        const parsedUserInfo = JSON.parse(userInfo || "{}");
        const { token } = parsedUserInfo;

        const response = await CourseAssessmentResultsApiService.getAllCourseAssessmentResults(token);
        if (response && response.length > 0) {
          const completedAssessments = response.filter(assessment => assessment.status === "COM");
          setMarkedTests(completedAssessments);
        }
      } catch (error) {
        console.error("Failed to fetch course assessments:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchAllCourseAssessments();
  }, []);

  const handleSeeAll = () => {
    navigate('/assessments');
  };

  const fetchFullExamData = async (assessmentId) => {
    try {
      const userInfo = localStorage.getItem("user");
      const parsedUserInfo = JSON.parse(userInfo || "{}");
      const { token } = parsedUserInfo;

      const response = await AssessmentAPIService.fetchAssessment(assessmentId, token);
      if (response) {
        return await response;
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to fetch assessment data. Please try again later.'
        });
        return null;
      }
    } catch (error) {
      rollbar.error("Error fetching full exam data:", error);
      console.error("Error fetching full exam data:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An unexpected error occurred. Please try again later.'
      });
      return null;
    }
  };


  const handleEdit = async (paperId) => {
  try {
    const assessment = await fetchFullExamData(paperId);

    if (assessment) {
      // Construct assessmentDetails with the required structure
      const assessmentDetails = {
        examId: assessment.id,
        name: assessment.name,
        subjectName: assessment.subject.name, // Accessing subject name from the nested subject object
        subjectId: assessment.subject.id,     // Accessing subject ID from the nested subject object
        grade: assessment.grade,
        type: assessment.type,
        date: assessment.assessment_date,
        questions: assessment.questions || [], // Fallback to empty array if no questions are provided
        instructions: assessment.instructions  // This will use the instructions text directly
      };

      localStorage.removeItem("assessmentDetails");

      localStorage.setItem("assessmentDetails", JSON.stringify(assessmentDetails));

      // Navigate to the assessment details page with the constructed assessmentDetails state
      navigate("/create-assessment", { state: { assessmentDetails } });
    } else {
      rollbar.warning("Failed to fetch full assessment data.");
      console.error("Failed to fetch full assessment data.");
    }
  } catch (error) {
    rollbar.error("Error during handleEdit:", error);
    console.error("Error during handleEdit:", error);
  }
};

  const handleModalClose = () => {
    if (currentAnnouncementIndex < announcements.length - 1) {
      setCurrentAnnouncementIndex(currentAnnouncementIndex + 1);
    } else {
      setModalOpen(false);
    }
  };

  if (loading) {
    return (
      <div className="loading-overlay">
        <CircularProgress />
      </div>
    );
  }

  const handleDelete = (testId) => {
    setRecentlyWorkedOn(recentlyWorkedOn.filter(test => test.id !== testId));
    setBaselineTests(baselineTests.filter(test => test.id !== testId));
    setUpcomingTests(upcomingTests.filter(test => test.id !== testId));
  };

  const noTestsAvailable = recentlyWorkedOn.length === 0 && baselineTests.length === 0 && upcomingTests.length === 0 && markedTests.length === 0;

  const handleViewResults = (id)=>{
    navigate(`/analysis/${id}`);
  }

    const handleSeeAllMarkedTests = () => {
      navigate("/get-assessment-course?status=completed");
  };

   if (loading) {
    return (
      <div className="loading-overlay">
        <CircularProgress />
      </div>
    );
  }

  const openAnalysisModal = (test) => {
    setSelectedTestAnalysis(test);
    setAnalysisModalOpen(true);
  };

  const closeAnalysisModal = () => {
    setAnalysisModalOpen(false);
    setSelectedTestAnalysis(null);
  };
  return (
    <div className="dashboard">
      <FeatureAnnouncementModal
        open={modalOpen}
        onClose={handleModalClose}
        announcement={announcements[currentAnnouncementIndex]}
      />

      <div className="main-content">
        {!noTestsAvailable && (
          <Box className="overview-screen-header">
            <div className="overview-screen-header-content">
              <div className="overview-screen-right-section"></div>
            </div>
          </Box>
        )}

        {noTestsAvailable ? (
          <div className="no-tests-container" style={{ textAlign: 'center', marginTop: '20%' }}>
            <Typography variant="h5" gutterBottom>
              Thank you for using Educase
            </Typography>
            <Typography variant="h6" gutterBottom>
              Click the button below to create your first paper
            </Typography>
            <Link to="/assessment-details">
              <Button
                className="create-paper-button"
                variant="contained"
                style={{ backgroundColor: 'primary', color: 'white', marginTop: '20px' }}
              >
                Create Paper
              </Button>
            </Link>
          </div>
        ) : (
          <>
            {recentlyWorkedOn.length > 0 && (
              <div className="scroll-section">
                <h3 className="section-heading">Here's what you've recently worked on</h3>
                <div className="horizontal-grid">
                  {recentlyWorkedOn.slice(0, visibleWidgets).map((paper, index) => (
                    <TestWidget
                      key={index}
                      testId={paper.id}
                      testName={paper.name}
                      grade={paper.grade}
                      subject={paper.subject.name}
                      date={paper.assessment_date}
                      totalMarks={paper.total_marks}
                      lastUpdated={paper.last_updated}
                      onDelete={handleDelete}
                      handleEdit={handleEdit}
                    />
                  ))}
                </div>
                <Button className="see-all-button" onClick={handleSeeAll}>
                  See All
                </Button>
              </div>
            )}
            {markedTests.length > 0 && (
          <div className="scroll-section">
            <h3 className="section-heading">Here are recently marked tests</h3>
            <div className="marked-tests-list">
              {markedTests.slice(0, showAllMarkedTests ? markedTests.length : 4).map((test, index) => (
                <div key={index} className="marked-test-item">
                  <div className="test-name">
                    {test.exam_name} - <span className="course-name">{test.course_name}</span>
                  </div>
                  <div className="test-score">Average Score: {Math.round(test.average_mark)}%</div>
                  <div className="test-date">{new Date(test.assessment_date).toLocaleDateString()}</div>

                  {/* View Results Button */}
                  <Button
                    variant="text"
                    color="primary"
                    onClick={() => handleViewResults(test.id)}
                    sx={{ marginTop: "10px", marginRight: "5px" }}
                  >
                    View Results
                  </Button>
                </div>
              ))}
            </div>
            <Button
              className="see-all-button"
              onClick={handleSeeAllMarkedTests} // Changed to navigate to course-results
            >
              {showAllMarkedTests ? "Show Less" : "See All"}
            </Button>
          </div>
        )}
          </>
        )}
      </div>
  {/* AI Analysis Modal */}
      <Modal
        open={analysisModalOpen}
        onClose={closeAnalysisModal}
        aria-labelledby="analysis-modal-title"
        aria-describedby="analysis-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Paper
          style={{
            width: '100%',
            maxWidth: '600px',
            margin: '0 20px',
            padding: '0',
            borderRadius: '8px',
            overflow: 'hidden',
          }}
          elevation={3}
        >
          {/* Modal Header with Background and Learner Stats */}
          <Box
            style={{
              backgroundColor: '#5F9EA0',
              padding: '20px',
              color: '#fff',
              textAlign: 'center',
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {/* Close Button positioned to the right but outside of the title area */}
            <IconButton
              onClick={closeAnalysisModal}
              style={{
                position: 'absolute',
                top: '10px',
                right: '10px',
                color: '#fff',
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>

            {/* Assessment Name and Course */}
            <Typography variant="h6" style={{ fontWeight: 'bold', textAlign: 'left' }}>
              {selectedTestAnalysis?.exam_name || "Assessment"} - {selectedTestAnalysis?.course_name || "Course"}
            </Typography>
          </Box>

          {/* Learner Stats in Header */}
          <Box
            display="flex"
            justifyContent="center"
            padding="10px"
            style={{ backgroundColor: '#e0f2f1' }}
          >
            <Typography variant="body2" style={{ marginRight: '20px' }}>Passed: {selectedTestAnalysis?.passed_learners || 0}</Typography>
            <Typography variant="body2">Failed: {selectedTestAnalysis?.failed_learners || 0}</Typography>
          </Box>

          {/* Modal Content */}
          <Box padding="20px">
            <Typography variant="h5" id="analysis-modal-title" style={{ textAlign: 'center', marginBottom: '20px' }}>
              AI Analysis
            </Typography>

            {selectedTestAnalysis ? (
              <>
                <Typography variant="h6" style={{ marginTop: '20px' }}>Comments</Typography>
                <DisplayContent content={selectedTestAnalysis.comment || "No comments available."} />

                <Typography variant="h6" style={{ marginTop: '20px' }}>Recommendations</Typography>
                <DisplayContent content={selectedTestAnalysis.recommendations || "No recommendations available."} />
              </>
            ) : (
              <Typography>Loading...</Typography>
            )}
          </Box>
        </Paper>
      </Modal>
    </div>
  );
};

export default Home;
