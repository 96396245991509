import React, { useCallback, useEffect, useRef, useState } from "react";
import axios from "axios";
import { useDropzone } from "react-dropzone";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Webcam from "react-webcam";
import getConfig from "../../../../config/index";
import "./ImageUploader.css";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Avatar from "@mui/material/Avatar";
import CloseIcon from "@mui/icons-material/Close";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";


const ImageUploadComponent = ({
                                  subject,
                                  grade,
                                  assessmentId,
                                  learnerId,
                                  firstName,
                                  lastName,
                                  onImageUpload,
                                  onImageDelete,
                                  setSelectedImages,
                                  existingImages,
                                  handleCloseViewImagesModal,
                                  handleCloseUploadModal,
                                  setUploadedUrls
}) => {
    const [files, setFiles] = useState([]);
    const [setUploadProgress] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [activeUploads, setActiveUploads] = useState(0);
    const [openCamera, setOpenCamera] = useState(false);
    const [capturedImages, setCapturedImages] = useState([]);
    const webcamRef = useRef(null);

    const isMobile = window.innerWidth <= 767;

    useEffect(() => {
        // Load existing images
        const initializedFiles = existingImages.map((url, index) => ({
            file: {name: `ExistingImage_${index}`},
            name: `ExistingImage_${index}`,
            url
        }));
        setFiles(initializedFiles);
    }, [existingImages]);

    const dataURItoBlob = (dataURI) => {
        const byteString = atob(dataURI.split(',')[1]);
        const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], {type: mimeString});
    };

    useEffect(() => {
        if (activeUploads === 0) {
            setIsLoading(false);
        }
    }, [activeUploads]);

    const handleReorder = (index, direction) => {
        const newFiles = [...files];
        if (direction === "up" && index > 0) {
            [newFiles[index - 1], newFiles[index]] = [newFiles[index], newFiles[index - 1]];
        } else if (direction === "down" && index < newFiles.length - 1) {
            [newFiles[index + 1], newFiles[index]] = [newFiles[index], newFiles[index + 1]];
        }
        setFiles(newFiles);

        // Update the order in uploadedUrls only if setUploadedUrls and learnerId are defined
        if (setUploadedUrls && learnerId) {
            const reorderedUrls = newFiles.map((file) => file.url);
            setUploadedUrls((prevUrls) => ({
                ...prevUrls,
                [learnerId]: reorderedUrls,
            }));
        }
    };

    const onDrop = useCallback(async (acceptedFiles) => {
        setIsLoading(true);
        setActiveUploads(acceptedFiles.length);
        const config = getConfig();

        for (const file of acceptedFiles) {
            const hashedFileName = `educase_learners_answer_${generateRandomHash()}_${subject}_${grade}.png`;
            try {
                const formData = new FormData();
                formData.append("document", file, hashedFileName);
                formData.append("userId", learnerId);
                formData.append("assessmentId", assessmentId);
                formData.append("environment", config.api.base_url);
                formData.append("pdfConversion", "pending");

                const response = await axios.post(`https://scriptsworker.educasetools.com/upload-pdf`, formData, {
                    onUploadProgress: (progressEvent) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        setUploadProgress(prev => ({
                            ...prev,
                            [hashedFileName]: {state: "pending", percentage: percentCompleted}
                        }));
                    },
                    headers: {
                        "X-Custom-Auth-Key": "94CA5CDACCEEED5A8BCA28C853137",
                        "Content-Type": "multipart/form-data"
                    }
                });

                const fileUrl = response.data.url;
                onImageUpload(fileUrl);

                setSelectedImages(prevImages => [...prevImages, fileUrl]);
                setUploadProgress(prev => ({
                    ...prev,
                    [hashedFileName]: {state: "done", percentage: 100, url: fileUrl}
                }));
                setFiles(prevFiles => [...prevFiles, {file, name: file.name, url: fileUrl}]);

                // Only call the modal close function if it's defined
                if (handleCloseUploadModal) {
                    handleCloseUploadModal();
                } else if (handleCloseViewImagesModal) {
                    handleCloseViewImagesModal();
                }
            } catch (error) {
                setFiles(prevFiles => prevFiles.map(f => f.name === file.name ? {...f, error: true} : f));
                setUploadProgress(prev => ({
                    ...prev,
                    [hashedFileName]: {state: "error", percentage: 0}
                }));
            } finally {
                setActiveUploads(prev => prev - 1);
            }
        }
    }, [subject, grade, learnerId, assessmentId, onImageUpload, setSelectedImages, setUploadProgress, handleCloseUploadModal, handleCloseViewImagesModal]);

    const handleDeleteAnswer = useCallback(async (file, event) => {
        event.stopPropagation();
        setIsLoading(true);

        const fileUrl = file.url;
        const fileUrlPath = new URL(fileUrl).pathname;

        try {
            await axios.delete(`https://scriptsworker.educasetools.com${fileUrlPath}`, {
                headers: {"X-Custom-Auth-Key": "94CA5CDACCEEED5A8BCA28C853137"}
            });
            onImageDelete(fileUrl);
            setFiles(prevFiles => prevFiles.filter(f => f.url !== fileUrl));
        } catch (error) {
            console.error("Error deleting file:", error);
        } finally {
            setIsLoading(false);
        }
    }, [onImageDelete]);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        onDrop,
        accept: "image/jpeg, image/png",
        maxSize: 5000000 // 5MB max size
    });

    const capture = useCallback(() => {
        if (webcamRef.current) {
            const imageSrc = webcamRef.current.getScreenshot();
            if (imageSrc) setCapturedImages(prev => [...prev, imageSrc]);
        }
    }, [webcamRef]);

    const handleWebcamUpload = () => {
        if (capturedImages.length > 0) {
            capturedImages.forEach(imageSrc => {
                const blob = dataURItoBlob(imageSrc);
                const file = new File([blob], `webcam_capture_${generateRandomHash()}.png`, {type: "image/png"});
                onDrop([file]);
            });
            setOpenCamera(false);
        }
    };

    return (
        <div>
            <div className="header-container">
                <Avatar className="header-avatar">
                    {firstName.charAt(0).toUpperCase()}{lastName.charAt(0).toUpperCase()}
                </Avatar>
                <h4 className="header-title">{firstName} {lastName}</h4>
                {isMobile && (
                    <IconButton onClick={() => setOpenCamera(true)} aria-label="open webcam" className="camera-icon">
                        <CameraAltIcon/>
                    </IconButton>
                )}
            </div>

            <div {...getRootProps({className: 'dropzone'})}>
                <input {...getInputProps()} />
                {isMobile ? (
                    <p>Tap to upload images</p>
                ) : isDragActive ? (
                    <p>Drop the files here...</p>
                ) : (
                    <p>Drag 'n' drop files here, or click to select files</p>
                )}
            </div>

            {isMobile && openCamera && (
                <Dialog open={openCamera} onClose={() => setOpenCamera(false)} maxWidth="md" fullWidth>
                    <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px'}}>
                        <IconButton onClick={() => setOpenCamera(false)} aria-label="close dialog">
                            <CloseIcon/>
                        </IconButton>
                    </div>
                    <DialogContent>
                        <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            style={{ width: '100%' }}
                            videoConstraints={{
                                facingMode: { exact: "environment" }  // This targets the back camera
                            }}
                        />
                        <Button onClick={capture} sx={{width: '100%'}}>Capture</Button>
                        <Button onClick={handleWebcamUpload} sx={{width: '100%'}}>Upload</Button>
                    </DialogContent>
                </Dialog>
            )}

            <div className="learner-uploaded-files">
                {isLoading ? (
                    <CircularProgress className="loading-circular-progress"/>
                ) : (
                    <div>
                      {files.map((file, index) => (
    <div key={file.name} className="uploaded-file">
        <div className="image-header">
            <p>{`Image ${index + 1}`}</p>
            <IconButton onClick={() => handleReorder(index, "up")} aria-label="move up">
                <ArrowUpwardIcon sx={{ color: "#5f9ea0" }} />
            </IconButton>
            <IconButton onClick={() => handleReorder(index, "down")} aria-label="move down">
                <ArrowDownwardIcon sx={{ color: "#5f9ea0" }} />
            </IconButton>
            <IconButton onClick={(event) => handleDeleteAnswer(file, event)} aria-label="delete">
                <DeleteIcon />
            </IconButton>
        </div>
        <img src={file.url} alt={file.name} className="uploaded-image"/>
    </div>
))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImageUploadComponent;

function generateRandomHash() {
    const arr = new Uint8Array(8);
    window.crypto.getRandomValues(arr);
    return Array.from(arr, dec => dec.toString(16).padStart(2, "0")).join("").substring(0, 16);
}
