import React from "react";

const ImageViewer = ({ imageUrls }) => {
    return (
        <div style={{ width: "100%", padding: "20px", overflowY: "auto", maxHeight: "100vh" }}>
            {imageUrls && imageUrls.length > 0 ? (
                imageUrls.map((url, index) => (
                    <img key={index} src={url} alt={`Learner Answer ${index + 1}`}
                         style={{ width: "100%", marginBottom: "10px", objectFit: "contain" }} />
                ))
            ) : (
                <p>No images available</p>
            )}
        </div>
    );
};

export default ImageViewer;
