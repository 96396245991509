import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Button from "@mui/material/Button";
import getConfig from "../../config";

const Analysis = () => {
    const navigate = useNavigate();
    const { caId } = useParams();
    const location = useLocation();
    const examName = location.state?.examName || "Assessment";

    // Get configuration based on environment
    const config = getConfig();
    const isLocal = config.api.base_url.includes("127.0.0.1") || config.api.base_url.includes("localhost");

    const userInfo = localStorage.getItem("user");
    const parsedUserInfo = JSON.parse(userInfo || "{}");
    const { token } = parsedUserInfo;

    // Streamlit app URL with auth token
    const streamlitUrl = isLocal
        ? `http://localhost:8501/?local=true&course_assessment_result=${caId}&token=${token}&embed=true`
        : `https://velle-educase-analysis.streamlit.app/?course_assessment_result=${caId}&token=${token}&embed=true`;

    return (
        <div style={{ display: "flex", flexDirection: "column", height: "100vh" }}>
            {/* Header */}
            <div style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "20px",
                backgroundColor: "#f3f7f8",
                borderBottom: "1px solid #d0d4d9",
            }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <HomeIcon style={{ cursor: "pointer", color: "#555f67" }} onClick={() => navigate(-1)} />
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        onClick={() => navigate("/get-assessment-course?status=completed")}
                        sx={{ textTransform: "none", borderRadius: "8px" }}
                    >
                        Back to List
                    </Button>
                </div>
                <h2 style={{ margin: 0, color: "#333" }}>{examName}</h2>
            </div>

            {/* Streamlit App */}
            <iframe
                src={streamlitUrl}
                style={{ flex: 1, width: "100%", border: "none" }}
                title="Analysis"
            />
        </div>
    );
};

export default Analysis;
